import { CheckCircle, Circle } from 'react-feather'

interface ICheckMarkProps {
  className?: string
  selected?: boolean
  size?: number
}

const CheckMark = ({
  className,
  selected = false,
  size = 18,
}: ICheckMarkProps) => {
  const classNames = `text-secondary1 ${className ? className : ''}`
  return (
    <div>
      {selected ? (
        <CheckCircle size={size} className={classNames} />
      ) : (
        <Circle size={size} className={classNames} />
      )}
    </div>
  )
}

export default CheckMark
