import { Track } from '@types'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import TreatmentPlan from '@components/pt1/YourPlan/TreatmentPlan'

export const WhatIsIncluded = ({
  track,
  textClassName,
  isCheckout = false,
}: {
  track: Track
  textClassName?: string
  isCheckout?: boolean
}) => {
  const [expanded, setExpanded] = useState(true)

  return (
    <div
      className={`${
        track === Track.BEHAVIORAL
          ? 'mt-2 pt-2 border-t border-secondary1/20'
          : 'my-2'
      }`}
    >
      <div
        onClick={() => setExpanded(!expanded)}
        className="inline-flex items-center space-x-2 cursor-pointer hidden"
      >
        <div className={`font-medium ${textClassName}`}>
          {expanded ? 'Hide' : 'Show'} What&apos;s Included
        </div>
        {!expanded && <ChevronDownIcon className="h-4 w-4 mr-1" />}
        {expanded && <ChevronUpIcon className="h-4 w-4 mr-1" />}
      </div>
      {expanded && <TreatmentPlan hideTitle isCheckout={isCheckout} />}
    </div>
  )
}
