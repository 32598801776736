/* eslint-disable @next/next/no-img-element */
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Pagination } from 'swiper'

const FullStar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_603)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20.26V0.0300293C11.72 0.0300293 11.44 0.160029 11.32 0.430029L8.00996 7.48003L0.63996 8.60003C0.0229596 8.71003 -0.20304 9.43503 0.20996 9.87003L5.56996 15.36L4.30996 23.13C4.19796 23.757 4.88096 24.207 5.40996 23.91L12 20.26ZM18.43 15.36L23.79 9.87003C24.203 9.43503 23.976 8.71003 23.36 8.60003L15.99 7.48003L12.68 0.430029C12.56 0.160029 12.28 0.0300293 12 0.0300293V20.26L18.59 23.91C19.128 24.204 19.801 23.75 19.69 23.13L18.43 15.36Z"
          fill="#FFD101"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_603">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const HalfStar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_607)">
        <path
          opacity="0.4"
          d="M23.79 9.87003L18.43 15.36L19.69 23.13C19.801 23.75 19.128 24.204 18.59 23.91L12 20.26V0.0300293C12.28 0.0300293 12.56 0.160029 12.68 0.430029L15.99 7.48003L23.36 8.60003C23.976 8.71003 24.203 9.43503 23.79 9.87003Z"
          fill="#FFD101"
        />
        <path
          d="M12 0.0300293V20.26L5.40996 23.91C4.88096 24.207 4.19796 23.757 4.30996 23.13L5.56996 15.36L0.20996 9.87003C-0.20304 9.43503 0.0229596 8.71003 0.63996 8.60003L8.00996 7.48003L11.32 0.430029C11.44 0.160029 11.72 0.0300293 12 0.0300293Z"
          fill="#FFD101"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_607">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function BeforeAndAfter({
  showName = true,
  showQuote = true,
  showHeader = true,
  title,
  subtitle,
}: {
  showName?: boolean
  showQuote?: boolean
  showHeader?: boolean
  title?: string
  subtitle: string
}) {
  const slides = [
    {
      beforeImage: '/t1-before.jpg',
      afterImage: '/t1-after.jpg',
      duration: 'Customer for 6 Months',
      name: 'Luna R.',
      quote:
        'I have been getting wonderful support from my coach Amber, and I truly feel I am making progress.',
    },
    {
      beforeImage: '/t2-before.jpg',
      afterImage: '/t2-after.jpg',
      duration: 'Customer for 6 Months',
      name: 'Marissa R.',
      quote:
        "This is the first time I've taken any kind of medication for weight loss. I've been on it for  6 mos now and it's been great.",
    },
    {
      beforeImage: '/t3-before.jpg',
      afterImage: '/t3-after.jpg',
      duration: 'Customer for 6 Months',
      name: 'Michael P.',
      quote:
        "I started Measured, I’m down roughly 16lbs. I've been sleeping better at night and I can actually run a little now!",
    },
  ]

  return (
    <div className="mt-14">
      {showHeader && (
        <div className="mb-8">
          <h1 className="quiz-heading-3xl">
            Join A Community Of 10,000+ Members Who Have Seen Real Results With
            Measured
          </h1>
          <div className="flex items-center flex-col justify-center gap-3 mt-9 mb-1 sm:mt-14">
            <div className="flex items-center gap-1.5">
              <FullStar />
              <FullStar />
              <FullStar />
              <FullStar />
              <HalfStar />
            </div>
            <p className="quiz-paragraph-sm">3,512 Reviews</p>
          </div>
          {title && <p className="quiz-heading-lg mt-8 mb-3">{title}</p>}
          <p className="text-center bg-white border border-secondary1/20 py-2.5 px-5 leading-6 rounded-3xl mx-auto text-sm w-fit">
            {subtitle}
          </p>
        </div>
      )}
      <Swiper
        modules={[Pagination]}
        preloadImages
        pagination={{
          clickable: true,
          el: '.before-after-pagination',
          bulletActiveClass: 'swiper-pagination-bullet-active',
          bulletClass: 'swiper-pagination-bullet',
        }}
        spaceBetween={15}
        slidesPerView={1}
        grabCursor={true}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {showName && (
              <p className="quiz-heading-2xl font-medium mt-8">{slide.name}</p>
            )}
            {showQuote && (
              <p className="quiz-paragraph center antialiased mt-3">
                {slide.quote}
              </p>
            )}
            <p className="quiz-heading-lg font-medium mt-8 mb-6">
              {slide.duration}
            </p>
            <div className="grid grid-cols-2 gap-4 hidden">
              <div className="col-span-1">
                <div className="w-full">
                  <div className="rounded-2xl bg-brand-primary-1-default flex items-center justify-center h-full overflow-hidden shadow-xl">
                    <img
                      src={slide.beforeImage}
                      width={275}
                      height={415}
                      alt="Before"
                      className="rounded-lg"
                    />
                  </div>
                </div>
                <p className="quiz-heading-lg font-medium py-4">Before</p>
              </div>
              <div className="col-span-1">
                <div className="w-full">
                  <div className="rounded-2xl bg-brand-primary-1-default flex items-center justify-center h-full overflow-hidden shadow-xl">
                    <img
                      src={slide.afterImage}
                      width={275}
                      height={415}
                      alt="After"
                      className="rounded-lg"
                    />
                  </div>
                </div>
                <p className="quiz-heading-lg font-medium py-4">After</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="before-after-pagination flex items-center justify-center mt-1" />
      </Swiper>
    </div>
  )
}
