import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { Track } from '@types'
import ContinueButton from '@components/ContinueButton'
import { getSlides } from './data'
import { HowMeasuredWorksSlide } from './HowMeasuredWorksSlide'

export default function HowMeasuredWorks({
  track,
  onClick,
}: {
  track: Track
  onClick: () => void
}) {
  return (
    <div className="mt-3 mb-20 sm:mb-40 how-msrd-works">
      <h1 className="quiz-heading-3xl mb-9">How Measured Works</h1>

      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: '.before-after-pagination',
          bulletActiveClass: 'swiper-pagination-bullet-active',
          bulletClass: 'swiper-pagination-bullet',
        }}
        spaceBetween={15}
        slidesPerView={1}
        grabCursor={true}
      >
        {getSlides(track).map((slide, index) => (
          <SwiperSlide key={index}>
            <HowMeasuredWorksSlide index={index + 1} {...slide} />
          </SwiperSlide>
        ))}
        <div className="before-after-pagination flex items-center justify-center mt-6" />
      </Swiper>
      <p className="quiz-paragraph-sm center my-6">
        Have questions along the way? Our dedicated team of doctors, pharmacists
        and care specialists is here for you.
      </p>
      <ContinueButton
        text="Get Started"
        size="xl"
        extraClasses="brand-button submit-button w-full mt-1"
        enabled={true}
        onClick={onClick}
      />
    </div>
  )
}
