import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import TreatmentPlan from '@components/YourPlan/TreatmentPlan'

export const WhatIsIncluded = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="mt-2 pt-2 border-t border-secondary1/20">
      <div
        onClick={() => setExpanded(!expanded)}
        className="flex items-center space-x-2 cursor-pointer"
      >
        <div className="font-medium">
          {expanded ? 'Hide' : 'Show'} What&apos;s Included
        </div>
        {!expanded && <ChevronDownIcon className="h-4 w-4 mr-1" />}
        {expanded && <ChevronUpIcon className="h-4 w-4 mr-1" />}
      </div>
      {expanded && <TreatmentPlan />}
    </div>
  )
}
