import ErrorIcon from '../../../../public/icons/close.svg'
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  PaymentRequestPaymentMethodEvent,
  StripeCardElement,
} from '@stripe/stripe-js'
import { stripePaymentAction } from '@utils/payments'
import debounce from 'lodash.debounce'
import classNames from 'classnames'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Stripe from 'stripe'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setCoupon, clearCoupon, selectCoupon } from 'redux/order'
import { setLoading } from 'redux/screen'
import { selectEmail, selectLeadId } from 'redux/user'
import {
  Plan,
  StripeCheckoutFormUserInfo,
  StripeCheckoutResponse,
  StripeCoupon,
} from '@types'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'
import ContinueButton from '@components/ContinueButton'
import { useExperiment } from 'redux/experiment'
import { toast } from 'react-toastify'
import { getEventId } from '@utils/tracking'

interface Props {
  plan: Plan
  togglePhoneHelp: () => void
  formDisabled: boolean
  setFormDisabled: (arg0: boolean) => void
  clientSecret: string
  postPayment: (
    result: StripeCheckoutResponse,
    userInfo: StripeCheckoutFormUserInfo,
  ) => void
}

type CouponMessage = {
  status: 'success' | 'error'
  message: string
}

const cardProps = {
  style: {
    base: {
      fontSize: '18.5px',
      lineHeight: '28px',
      color: '#392100',
      fontWeight: 400,
      fontFamily:
        'Estedad, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      '::placeholder': {
        color: '#110F0D26',
        fontSize: '20px',
        lineHeight: '28px',
      },
      ':-webkit-autofill': {
        color: '#392100',
      },
    },
    complete: {
      lineHeight: '2.3rem',
    },
    invalid: {
      iconColor: '#bd3131',
      color: '#bd3131',
    },
  },
}

const StripeCheckoutForm = ({
  plan,
  togglePhoneHelp,
  formDisabled,
  setFormDisabled,
  clientSecret,
  postPayment,
}: Props) => {
  const dispatch = useAppDispatch()
  const railsLeadID = useAppSelector(selectLeadId)
  const email = useAppSelector(selectEmail)
  const couponCode = useAppSelector(selectCoupon)
  const [nameValue, setNameValue] = useState('')
  const [couponObject, setCouponObject] = useState<StripeCoupon | null>(null)
  const [emailValue, setEmailValue] = useState(email)
  const [couponCodeField, setCouponCodeField] = useState(true)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [couponMessage, setCouponMessage] = useState<CouponMessage | null>()
  const [paymentRequest, setPaymentRequest] = useState<any | null>(null)
  const couponInput = useRef<any>()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneValid, setPhoneValid] = useState(false)
  const phoneRef = useRef<IntlTelInput>(null)
  const { experiment } = useExperiment()

  const stripe = useStripe()
  const elements = useElements()

  // Set default coupon code to BLACKFRI23
  // we do this by setting the input field and then triggering
  // couponchangehandler
  useEffect(() => {
    setTimeout(() => {
      if (couponCodeField && couponCode?.code) {
        couponInput.current.value = couponCode?.code || ''
        couponChangeHandler()
      }
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponCodeField])

  const couponChangeHandler = useCallback(async () => {
    const coupon = couponInput?.current?.value || ''

    console.log('validating', coupon)
    setCouponMessage(null)
    setFormDisabled(true)
    dispatch(clearCoupon())

    const couponResponse = await fetch('/api/coupon', {
      body: JSON.stringify({
        coupon,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(response => response.json())

    if (couponResponse.success) {
      dispatch(
        setCoupon({
          code: coupon,
          ...couponResponse.coupon,
        }),
      )
      setCouponObject(couponResponse.coupon)
      setCouponMessage({ status: 'success', message: 'Discount applied!' })
    } else {
      setCouponMessage({ status: 'error', message: 'Coupon not found' })
    }

    setFormDisabled(false)
  }, [dispatch, setFormDisabled])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCouponHandler = useCallback(
    debounce(couponChangeHandler, 300),
    [],
  )

  const paymentFormInputHandler = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      switch (ev.target.type) {
        case 'text':
          setNameValue(ev.target.value)
          break
        case 'email':
          setEmailValue(ev.target.value)
          break
      }
    },
    [],
  )

  const inputClassName = `quiz-input checkout w-full ${
    formDisabled ? 'disabled' : ''
  }`

  const handleCardErrors = useCallback(
    (event: { message?: string }) => {
      if (event.message) {
        const newErrorMessages = [...errorMessages, event.message]
        setErrorMessages(newErrorMessages)
      } else {
        const oldErrorMessages = errorMessages
        oldErrorMessages.pop()

        setErrorMessages(oldErrorMessages)
      }
      setFormDisabled(false)
      dispatch(setLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorMessages, setFormDisabled],
  )

  const showError = useCallback(
    (message: string) => {
      handleCardErrors({
        message,
      })
    },
    [handleCardErrors],
  )

  const createStripeCustomerAndSubscription = useCallback(
    async (
      paymentMethodId: string,
      userInfo: StripeCheckoutFormUserInfo,
    ): Promise<StripeCheckoutResponse | any> => {
      const lineItems = [plan]
      return stripePaymentAction({
        ...userInfo, // name, email, phone
        priceId: plan.stripe_price_id,
        paymentMethodId: paymentMethodId,
        coupon: couponObject ? couponObject.id : null,
        purchaseEventId: getEventId('Purchase'),
        railsLeadID,
        lineItems,
        oneTimeFee: plan.oneTimeFee,
        trialPeriodDays: plan.trialPeriodDays,
      })
        .then(response => response.json())
        .then(result => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result.error
          }
          return result
        })
        .catch(handleCardErrors)
    },
    [railsLeadID, plan, handleCardErrors, couponObject],
  )

  const handlePayment = useCallback(
    async (
      paymentMethodId: string,
      userInfo?: StripeCheckoutFormUserInfo,
      ev?: PaymentRequestPaymentMethodEvent,
    ) => {
      if (!userInfo) {
        if (!emailValue || !nameValue) {
          throw new Error('Email and name are required to handle payment')
        }

        userInfo = {
          name: nameValue.trim(),
          email: emailValue.trim(),
          phone: intlTelInputUtils.formatNumber(
            phoneNumber,
            'US',
            intlTelInputUtils.numberFormat.E164,
          ),
        }
      }

      try {
        const result: StripeCheckoutResponse =
          await createStripeCustomerAndSubscription(paymentMethodId, userInfo)

        console.log(`stripe customer and subscription created`)

        const { stripeResponse } = result

        if (
          stripeResponse &&
          ['active', 'succeeded', 'trialing'].includes(stripeResponse.status)
        ) {
          ev?.complete('success')
          postPayment(result, userInfo)
        } else if (
          (
            (stripeResponse.latest_invoice as Stripe.Invoice)
              .payment_intent as Stripe.PaymentIntent
          ).status === 'requires_payment_method'
        ) {
          const paymentResult = await stripe?.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId,
          })
          if (paymentResult?.error) {
            ev?.complete('fail')
            throw paymentResult
          } else {
            if (paymentResult?.paymentIntent.status === 'succeeded') {
              ev?.complete('success')
              postPayment(result, userInfo)
            }
          }
        }
      } catch (err) {
        throw Error(
          'There was an error processing your card. Please try again or contact support at support@trymeasured.com',
        )
      }
    },
    [
      nameValue,
      emailValue,
      phoneNumber,
      clientSecret,
      stripe,
      postPayment,
      createStripeCustomerAndSubscription,
    ],
  )

  const phoneChangeHandler = (isValid: boolean, phoneNumber: any) => {
    phoneRef?.current?.tel?.classList.remove('invalid')
    setPhoneValid(isValid)
    setPhoneNumber(phoneNumber)
  }

  const handleFormSubmit = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault()

      setFormDisabled(true)
      dispatch(setLoading(true))

      if (elements == null) {
        console.log('elements not initialized')
        return
      }

      if (!phoneValid) {
        phoneRef?.current?.tel?.classList.add('invalid')
        showError('Please enter a valid phone number')
        return
      }

      trackMixpanelEvent('Checkout Form Submit', experiment?.funnel)

      const paymentMethodResult = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement) as StripeCardElement,
      })

      if (paymentMethodResult?.error) {
        handleCardErrors(paymentMethodResult.error)

        trackMixpanelEvent('Payment Error', experiment?.funnel, {
          error: paymentMethodResult.error,
        })
      } else if (paymentMethodResult?.paymentMethod?.id) {
        try {
          await handlePayment(paymentMethodResult.paymentMethod.id)
        } catch (err: any) {
          showError(err.message)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      elements,
      stripe,
      handleCardErrors,
      handlePayment,
      setFormDisabled,
      showError,
      phoneValid,
    ],
  )

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          amount: plan.amount,
          label: 'Total',
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: false,
      })

      paymentRequest.on('paymentmethod', async ev => {
        const userInfo: StripeCheckoutFormUserInfo = {
          name: ev?.payerName?.trim() || '',
          email: ev?.payerEmail?.trim() || '',
          phone: ev?.payerPhone?.trim() || '',
        }

        await handlePayment(ev.paymentMethod.id, userInfo, ev)
      })

      paymentRequest.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(paymentRequest)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe])

  useEffect(() => {
    if (couponCode) {
      couponInput.current.value = couponCode?.code
      setCouponObject(couponCode)
    }
    if (!railsLeadID) {
      toast('Something went wrong, please try again later.', {
        type: 'error',
        theme: 'colored',
        autoClose: 5000000,
        progress: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !railsLeadID ? null : (
    <form name="checkout-form" id="checkout-form" onSubmit={handleFormSubmit}>
      <input
        type="hidden"
        name="product"
        id="product"
        defaultValue={plan.productName}
      />
      <input
        type="hidden"
        name="product_id"
        id="product_id"
        defaultValue={plan.id}
      />

      {paymentRequest && (
        <div id="payment-request-button" className="mb-8">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}

      <fieldset className="my-4">
        <div className="grid grid-cols-6 md:grid-cols-12 gap-4 md:gap-3 text-secondary1/60">
          <div className="col-span-6 md:col-span-5">
            <label
              htmlFor="name"
              data-tid="form.name_label"
              className="uppercase font-semibold text-sm"
            >
              Name
            </label>
            <input
              id="name"
              data-tid="form.name_placeholder"
              className={inputClassName}
              type="text"
              placeholder="Jane Doe"
              required={true}
              autoComplete="name"
              value={nameValue}
              onChange={paymentFormInputHandler}
            />
          </div>
          <div className="col-span-6 md:col-span-7">
            <label
              htmlFor="email"
              data-tid="form.email_label"
              className="uppercase font-semibold text-sm"
            >
              Email
            </label>
            <input
              id="email"
              data-tid="form.email_placeholder"
              className={inputClassName}
              type="email"
              placeholder="janedoe@gmail.com"
              required
              autoComplete="email"
              value={emailValue || ''}
              onChange={paymentFormInputHandler}
            />
          </div>
          <div className="col-span-6 md:col-span-5">
            <label
              htmlFor="phone_number"
              data-tid="form.phone_label"
              className="uppercase font-semibold text-sm flex items-center pt-0.5"
            >
              Cell Phone Number
            </label>
            <IntlTelInput
              ref={phoneRef}
              defaultCountry={'US'}
              allowDropdown={false}
              formatOnInit={true}
              containerClassName="intl-tel-input w-full"
              inputClassName={[inputClassName, 'w-full', 'pb-0.5'].join(' ')}
              data-tid="form.phone_placeholder"
              format={true}
              autoComplete="phone"
              telInputProps={{
                maxLength: 14,
                minLength: 14,
                id: 'phone_number',
              }}
              onPhoneNumberChange={phoneChangeHandler}
            />
            <div className="text-secondary1 text-xs mt-1 block md:hidden">
              We&#39;ll text you a link to our app here. (
              <span
                className="underline cursor-pointer ml-1"
                onClick={togglePhoneHelp}
              >
                More Info
              </span>
              )
            </div>
          </div>
          <div className="col-span-6 md:col-span-7">
            <label
              htmlFor="card-element"
              data-tid="form.card_label"
              className="uppercase font-semibold text-sm flex items-center"
            >
              Credit Card
            </label>
            <div className="p-1 card-element-container bg-secondary1/5">
              <CardElement
                id="card-element"
                className={inputClassName}
                options={cardProps}
              />
            </div>
          </div>
        </div>
        <div className="text-secondary1 text-xs mt-1 hidden md:block text-secondary1/60">
          We&#39;ll text you a link to our app here.
          <span
            className="underline cursor-pointer ml-1"
            onClick={togglePhoneHelp}
          >
            More Info
          </span>
          )
        </div>
        <div
          className={[
            'sm:col-span-6 mt-2 text-secondary1/60',
            couponCodeField ? 'block' : 'hidden',
          ].join(' ')}
        >
          <div className="flex items-center justify-between">
            <label
              htmlFor="coupon"
              data-tid="form.coupon"
              className="uppercase font-semibold text-sm flex items-center"
              style={{ marginTop: 6 }}
            >
              Promo Code
            </label>
            {couponMessage && (
              <div
                className={classNames({
                  'italic text-xs w-full text-right font-semibold': true,
                  'text-green-600': couponMessage.status === 'success',
                  'text-red-600': couponMessage.status === 'error',
                })}
              >
                {couponMessage.message}
              </div>
            )}
          </div>
          <input
            id="coupon"
            onChange={debouncedCouponHandler}
            className={[inputClassName, 'w-full uppercase'].join(' ')}
            type="text"
            required={false}
            ref={couponInput}
            min={3}
          />
        </div>
        {Array.isArray(errorMessages) && errorMessages.length > 0 && (
          <div id="error-cont" className="error visible" role="alert">
            <ErrorIcon
              className="w-4 h-4 mr-2 leading-tight fill-current text-white"
              style={{ minWidth: 20 }}
            />
            <div id="error-message" className="message">
              {errorMessages[errorMessages.length - 1] ?? ''}
            </div>
          </div>
        )}
        <div className="terms-label text-secondary1/60">
          By continuing, you agree to the{' '}
          <a
            href="https://trymeasured.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          ,{' '}
          <a
            href="https://trymeasured.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          , and{' '}
          <a
            href="https://trymeasured.com/hipaa-policy"
            target="_blank"
            rel="noreferrer"
          >
            HIPAA Policy
          </a>
          .
        </div>

        <ContinueButton
          text="Complete Order"
          extraClasses="my-6"
          enabled={!formDisabled}
        />
      </fieldset>
      <div
        className={classNames({
          'text-gray-600 text-center italic text-sm my-2 text-secondary1/60':
            true,
          hidden: couponCodeField,
        })}
      >
        Have a redemption code?
        <span
          className="underline cursor-pointer ml-1 hover:text-gray-700"
          onClick={() => setCouponCodeField(true)}
        >
          Click here
        </span>
      </div>
    </form>
  )
}

export default StripeCheckoutForm
