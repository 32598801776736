/* eslint-disable @next/next/no-img-element */
import { Plan, Track } from '@types'
import { DiscountInlineText, DiscountCustomText } from '../DiscountLine'
import { WhatIsIncluded } from '../WhatIsIncluded'
import { OrderPlanItemHint } from './OrderPlanItemHint'
import { useAppSelector } from '../../../../redux/hooks'
import { selectCoupon } from '../../../../redux/order'

const OrderPlanItem = ({
  selectedPlan,
  title,
  imagePath,
  whatIsIncluded,
  hint,
  isCheckout = false,
  showCoupon = false,
}: {
  selectedPlan: Plan
  title: string
  imagePath?: string
  whatIsIncluded?: Track
  hint?: string
  isCheckout?: boolean
  showCoupon?: boolean
}) => {
  const couponCode = useAppSelector(selectCoupon)

  return (
    <>
      <h2 className={`order-title ${hint ? 'flex gap-1' : ''}`}>
        <span>{title}</span>
        {hint && <OrderPlanItemHint description={hint} />}
      </h2>
      <div className="order-item">
        {imagePath && (
          <div className="order-item-image">
            <img src={imagePath} alt={title} title={title} />
          </div>
        )}
        <div className="order-item-header">
          <div className="order-item-header-title">
            <div className="order-item-header-title-text">
              {selectedPlan.productName}
              {showCoupon && couponCode && (
                <>
                  <br />
                  <span className="font-serif font-medium text-xs flex text-red-500">
                    ({couponCode.name} - {couponCode.percent_off}% off)
                  </span>
                </>
              )}
            </div>
            <div className="order-item-header-price">
              {showCoupon && couponCode ? (
                <DiscountCustomText
                  originalPrice={selectedPlan.salePrice}
                  discountAmount={couponCode.percent_off}
                />
              ) : (
                <DiscountInlineText selectedPlan={selectedPlan} />
              )}
              {selectedPlan.billed_short && (
                <div className="order-item-header-price-recurring">
                  /{selectedPlan.billed_short}
                </div>
              )}
            </div>
          </div>
          <div className="quiz-paragraph-xs left">
            <div
              dangerouslySetInnerHTML={{
                __html: selectedPlan.description || '',
              }}
            ></div>
          </div>
          <div className="order-item-header-what-is-included">
            {whatIsIncluded && (
              <WhatIsIncluded
                track={whatIsIncluded}
                textClassName="quiz-paragraph-xs left"
                isCheckout={isCheckout}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderPlanItem
