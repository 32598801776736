import { ArrowRight } from 'react-feather'

const HtmlChild = ({ html }: { html: string }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} />
)

const ContinueButton = ({
  enabled,
  onClick,
  text = 'Continue',
  size = '',
  extraClasses = null,
  showArrow = true,
}: {
  enabled: boolean
  onClick?: () => void
  text?: string
  size?: string
  extraClasses?: string | null
  showArrow?: boolean
}) => {
  const buttonStyles = [
    'action-button',
    enabled ? 'enabled' : 'disabled',
    size,
    extraClasses,
  ].join(' ')

  return (
    <div className={['action-button-wrap', size].join(' ')}>
      <button
        type="submit"
        onClick={onClick}
        className={buttonStyles}
        disabled={!enabled}
      >
        <HtmlChild html={text} />
        {showArrow && (
          <ArrowRight className="h-5 w-5 relative text-secondary1 " />
        )}
      </button>
    </div>
  )
}

export default ContinueButton
