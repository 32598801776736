import { Plan } from '@types'
import NumberFormat from 'react-number-format'

const calculateDiscount = (selectedPlan: Plan) =>
  Math.round(100 - (selectedPlan.salePrice / selectedPlan.originalPrice) * 100)

export const DiscountLine = ({ selectedPlan }: { selectedPlan: Plan }) => {
  const discountAmount = calculateDiscount(selectedPlan)

  if (!discountAmount || selectedPlan.duration < 1) {
    return null
  }

  return (
    <div className="flex flex-row justify-stretch">
      <div>
        <span className="font-medium text-red-600">Discount</span> &mdash;{' '}
        <span className="italic">{discountAmount}% off</span>
      </div>
      <div className="flex-1" />
      <div className="text-right">
        <div className="text-red-600">
          <NumberFormat
            displayType={'text'}
            value={
              -Math.round((selectedPlan.originalPrice * discountAmount) / 100)
            }
            prefix="$"
          />
        </div>
      </div>
    </div>
  )
}
