import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  PaymentProcessorData,
  Plan,
  Processor,
  Purchased,
  Track,
  StripeCoupon,
  PromoCode,
} from '../../@types'
import { defaultPlan } from '../../data/plans/base'
import { RootState } from '../store'

type OrderState = {
  selectedPlan: Plan | null
  processor: Processor | null
  purchased: Array<Purchased>
  purchaseComplete: boolean
  paymentProcessorData: PaymentProcessorData | null
  coupon: StripeCoupon | null
  promoCode: PromoCode | null
}

// Define the initial state using that type
const initialState: OrderState = {
  selectedPlan: null,
  processor: null,
  purchased: [],
  purchaseComplete: false,
  paymentProcessorData: null,
  coupon: null,
  promoCode: null,
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearSelectedPlan: state => {
      state.selectedPlan = null
    },
    clearCoupon: state => {
      state.coupon = null
    },
    setProcessor: (state, action: PayloadAction<Processor>) => {
      state.processor = action.payload
    },
    setSelectedPlan: (state, action: PayloadAction<Plan>) => {
      state.selectedPlan = action.payload
    },
    setPurchased: (state, action: PayloadAction<Purchased[]>) => {
      state.purchased = action.payload
    },
    setCoupon: (state, action: PayloadAction<StripeCoupon | null>) => {
      state.coupon = action ? action.payload : null
    },
    setPromoCode: (state, action: PayloadAction<PromoCode | null>) => {
      state.promoCode = action.payload
    },
    setPurchaseComplete: (state, action: PayloadAction<boolean>) => {
      state.purchaseComplete = action.payload
    },
    setPaymentProcessorData: (
      state,
      action: PayloadAction<PaymentProcessorData>,
    ) => {
      state.paymentProcessorData = action.payload
    },
  },
})

export const selectProcessor = (state: RootState): Processor =>
  state.order.processor
export const selectSelectedPlan = (state: RootState): Plan =>
  state.order.selectedPlan || defaultPlan()

export const selectPurchaseComplete = (state: RootState): boolean =>
  state.order.purchaseComplete
export const selectPurchased = (state: RootState): Purchased[] =>
  state.order.purchased
export const selectCoupon = (state: RootState): StripeCoupon =>
  state.order.coupon
export const selectPromoCode = (state: RootState): PromoCode | null =>
  state.order.promoCode
export const selectPaymentProcessorData = (
  state: RootState,
): PaymentProcessorData => state.order.paymentProcessorData

export const selectPurchasedPlan = (state: RootState): Plan =>
  selectPaymentProcessorData(state)?.selectedPlan

export const selectPurchasedTrack = (state: RootState): Track =>
  selectPurchasedPlan(state)?.track

export const {
  clearCoupon,
  clearSelectedPlan,
  setCoupon,
  setPaymentProcessorData,
  setProcessor,
  setPromoCode,
  setPurchaseComplete,
  setPurchased,
  setSelectedPlan,
} = orderSlice.actions

export default orderSlice.reducer
