import { Plan, Track } from '@types'
import { useAppSelector } from '../../redux/hooks'
import { selectCoupon, selectSelectedPlan } from '../../redux/order'
import { applyCouponToPlan } from '@utils/plans'
import NumberFormat from 'react-number-format'
import { useEffect, useState } from 'react'
import { WhatIsIncluded } from './WhatIsIncluded'
import { DiscountLine } from './DiscountLine'
import { CouponLine } from './CouponLine'

const OrderSummary = () => {
  const selectedPlan = useAppSelector(state => selectSelectedPlan(state))
  const coupon = useAppSelector(selectCoupon)
  const [trackName, setTrackName] = useState<string | null>(null)
  const [updatedPlan, setUpdatedPlan] = useState<Plan>(selectedPlan)

  useEffect(() => {
    const sufix = selectedPlan.track === Track.MEDICAL ? 'Rx' : 'Nutrition'
    setTrackName(`Measured ${sufix}`)
  }, [selectedPlan])

  useEffect(() => {
    setUpdatedPlan(applyCouponToPlan(selectedPlan, coupon))
  }, [selectedPlan, coupon])

  return (
    <div className="order-summary">
      <div className="flex flex-row justify-stretch">
        <div className="font-medium">
          {trackName} - {selectedPlan.productName.replace('(Rx)', '')}
        </div>
        <div className="flex-1" />
        <div className="text-right">
          <NumberFormat
            displayType="text"
            value={selectedPlan.originalPrice}
            prefix="$"
          />
        </div>
      </div>
      <DiscountLine selectedPlan={selectedPlan} />
      <CouponLine selectedPlan={updatedPlan} coupon={coupon} />
      <WhatIsIncluded />
      <div className="flex flex-row justify-between mt-2 pt-2 border-t border-secondary1/20 text-base">
        <div>
          <span className="font-semibold tracking-wide">
            TODAY&#39;S TOTAL:
          </span>
        </div>
        <div className="text-right font-semibold">
          <NumberFormat
            displayType="text"
            value={
              updatedPlan.salePrice % 1 === 0
                ? updatedPlan.salePrice
                : updatedPlan.salePrice.toFixed(2)
            }
            prefix="$"
          />
        </div>
      </div>
    </div>
  )
}

export default OrderSummary
