import PlanDetailsCard from '@components/pt1/Plan/PlanDetailsCard'
import ContinueButton from '@components/ContinueButton'
import Router from 'next/router'
import { useExperiment } from 'redux/experiment'
import { useEffect } from 'react'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'

export default function WhatsNext() {
  const { experiment } = useExperiment()

  const onClick = () => {
    return Router.push('/checkout')
  }

  useEffect(() => {
    trackMixpanelEvent("Here's what's next page", experiment?.funnel)

    if (typeof window !== 'undefined' && !!document) {
      document.querySelector('body')?.classList?.add('whats-next')
      setTimeout(() => {
        document.querySelector('body')?.classList?.add('whats-next')
      }, 50)
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.querySelector('body')?.classList?.remove('whats-next')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="max-w-lg w-full mx-auto">
      <div className="mb-2 md:mb-12">
        <h1 className="quiz-heading">
          {experiment?.dictionary?.whatsNextPage?.title}
        </h1>
      </div>
      <div className="mb-6">
        <div className="your-plan-container pt1">
          <div className="cards">
            {experiment?.dictionary?.whatsNextPage?.steps.map(
              (
                step: { title: string; description: string | undefined },
                index: number,
              ) => (
                <PlanDetailsCard
                  key={index}
                  step={index + 1}
                  title={step.title}
                  description={step.description}
                />
              ),
            )}
          </div>
        </div>
      </div>
      <ContinueButton
        text="Continue"
        enabled
        onClick={onClick}
        extraClasses="dark-button"
      />
    </div>
  )
}
