const Loading = ({ text = 'Loading...', bottomText = '' }) => (
  <div className="flex flex-col w-full flex-1 items-center justify-center relative z-10">
    {text && (
      <h3 className="quiz-heading-2xl text-center text-xl font-medium">
        {text}
      </h3>
    )}
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
      <div className="bounce4"></div>
      <div className="bounce5"></div>
    </div>
    {bottomText && <p className="quiz-paragraph">{bottomText}</p>}
  </div>
)

export default Loading
