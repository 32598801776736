import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { clearQuizProgress } from 'redux/quiz/steps'
import Header from '@components/Header'
import Footer from '@components/Footer'
import { SaveUpToBanner } from '@components/Banner/SaveUpToBanner'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const [layoutMinHeight, setLayoutMinHeight] = useState('100vh')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { asPath, query, pathname } = router

  const queryClass = Object.keys(query)
    .map(k => query[k] && `${k}-${query[k]}`)
    .join(' ')

  const pathClass = pathname.split('/').join(' ')

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      // if the path is the initial home page, clear the quiz progress
      if (asPath === '/') {
        dispatch(clearQuizProgress())
      }
    })
  }, [dispatch, router.events])

  useEffect(() => {
    // update min height to be the height of the window/viewport only for mobile screen
    if (window.innerWidth < 768) {
      setLayoutMinHeight(`${window.innerHeight}px`)
    }
  }, [layoutMinHeight])

  return (
    <>
      <SaveUpToBanner />
      <div
        className={`layout ${pathClass} ${queryClass}`}
        style={{ minHeight: layoutMinHeight }}
      >
        <Header />
        <div className="items-center flex flex-col flex-1">
          <div className="layout-inner-1">
            <div className="layout-inner-2">
              <div className="w-full flex flex-col items-center justify-start md:pt-8">
                {children}
              </div>
            </div>
          </div>
          <Footer path={asPath} />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Layout
