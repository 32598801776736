import {
  QuizInsurance,
  QuizSteps,
  QuizStepType,
  StepId as StepIdBase,
} from '@types'
import { conditionsQuestion } from '@data/questions/base'

export const StepId = {
  ...StepIdBase,
  MEDICATIONS_OF_INTEREST: 'medications_of_interest',
  PREFERRED_PHARMACY: 'preferred_pharmacy',
  IDEAL_COACH: 'ideal_coach',
  PREFERRED_COACHING_STYLE: 'preferred_coaching_style',
  PREFERRED_MEAL_PLAN: 'preferred_meal_plan',
  HOW_DID_YOU_HEAR_ABOUT_US: 'how_did_you_hear_about_us',
}

export const postQuiz: QuizSteps[] = [
  {
    id: StepId.HOW_DID_YOU_HEAR_ABOUT_US as StepIdBase,
    type: QuizStepType.MULTIPLE_CHOICE,
    label: 'How did you hear about us?',
    compact: true,
    options: [
      {
        name: 'Facebook',
        value: 'facebook',
      },
      {
        name: 'Instagram',
        value: 'instagram',
      },
      {
        name: 'TikTok',
        value: 'tiktok',
      },
      {
        name: 'Friend',
        value: 'friend',
      },
      {
        name: 'Email',
        value: 'email',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
    ],
  },
]

export const medicalPostQuiz: QuizSteps[] = [
  {
    id: StepId.PREFERRED_PHARMACY as StepIdBase,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'What is your preferred pharmacy?',
    options: [
      {
        name: 'Walgreens',
        value: 'walgreens',
      },
      {
        name: 'CVS',
        value: 'cvs',
      },
      {
        name: 'Walmart',
        value: 'walmart',
      },
      {
        name: 'Ralphs',
        value: 'ralphs',
      },
      {
        name: 'Rite Aid',
        value: 'rite aid',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
    ],
  },
  ...postQuiz,
]

export const questions: QuizSteps[] = [
  {
    id: StepId.GENDER,
    type: QuizStepType.SINGLE_CHOICE,
    label:
      "Get your <span class='mc-callout-1'>personalized GLP1 weight loss plan</span><br class='hidden md:block' /> by completing this short quiz.",
    options: [
      {
        name: 'Male',
        value: 'male',
      },
      {
        name: 'Female',
        value: 'female',
      },
    ],
  },
  {
    id: StepId.HEIGHT_WEIGHT,
    type: QuizStepType.HEIGHT_WEIGHT,
    label: 'What is your height<br /> and weight?',
    hint: "We'll use this to calculate<br /> your Body Mass Index (BMI).",
    showHeight: true,
    showWeight: true,
    weightId: StepId.CURRENT_WEIGHT,
  },
  {
    id: StepId.CONTENT_PERSONALIZED,
    type: QuizStepType.CONTENT,
    content_type: 'html',
    label: 'How we create your plan',
    hint: "<span class='callout-text'>We ask a few questions about your background, health history, and biology that will help us determine if you're eligible for a GLP1 Measured medical plan.<br /><br /> Once you complete your lab work, your clinician will reviews the results and health history to determine the appropriate treatment plan.</span>",
  },
  {
    id: StepId.ZIPCODE,
    type: QuizStepType.ZIPCODE,
    label: 'Where do you currently live?',
    hint: 'Help us determine if you’re in our service area.',
  },
  { ...conditionsQuestion },
  {
    id: StepId.HEALTH_CONDITIONS,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: '(you can pick more than one)',
    compact: true,
    label: 'Do you have any of the below health conditions?',
    options: [
      {
        name: 'Diabetes',
        value: 'diabetes',
      },
      {
        name: 'Heart Disease',
        value: 'heart_disease',
      },
      {
        name: 'High Blood Pressure',
        value: 'high_blood_pressure',
      },
      {
        name: 'High Cholesterol',
        value: 'high_cholesterol',
      },
      {
        name: 'Sleep Apnea',
        value: 'sleep_apnea',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.WEIGHT_LOSS,
    type: QuizStepType.SLIDER,
    label: 'How much weight <br /> do you want to lose?',
    min: 0,
    maxSelector: 'selectMaxWeightLoss',
  },
  {
    id: StepId.BIRTHDAY,
    type: QuizStepType.BIRTHDAY,
    label: 'When were you born?',
    hint: 'Help us determine if Measured can serve you.',
    _comment:
      'Should have a content slide after this that either explains how we use this data or sets up the following questions.',
  },
  {
    id: StepId.MEDICATIONS_OF_INTEREST as StepIdBase,
    type: QuizStepType.SINGLE_CHOICE,
    hint: 'Select all that apply.',
    compact: true,
    label: 'What weight loss medications are you interested in?',
    options: [
      {
        name: 'GLP-1 (Wegovy, Ozempic, etc.)',
        value: 'glp1',
      },
      {
        name: 'Non GLP-1 (Metformin, Naltrexone, etc.)',
        value: 'non-glp1',
      },
      {
        name: 'Not sure',
        value: 'not_sure',
      },
    ],
  },
  {
    id: StepId.INSURANCE_PROVIDER as StepIdBase,
    type: QuizStepType.INSURANCE,
    label:
      'What form of health insurance will you use to fulfill your prescription?',
    options: [
      {
        name: 'Kaiser',
        value: QuizInsurance.KAISER,
      },
      {
        name: 'Medicare',
        value: QuizInsurance.MEDICARE,
      },
      {
        name: 'Medicaid',
        value: QuizInsurance.MEDICAID,
      },
      {
        name: 'Tri-care',
        value: QuizInsurance.TRI_CARE,
      },
      {
        name: 'Employer/Commercial (Anthem, Blue Cross, etc.)',
        value: QuizInsurance.COMMERCIAL,
      },
      {
        name: "I don't have insurance",
        value: QuizInsurance.NONE,
      },
    ],
  },
  {
    id: StepId.STRUGGLE,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: "(pick as many as you'd like)",
    compact: true,
    label: 'What are your biggest struggles with losing weight?',
    options: [
      {
        name: 'Lack of motivation',
        value: 'lack_motivation',
      },
      {
        name: 'Tempted by cravings',
        value: 'cravings',
      },
      {
        name: 'Stress eating',
        value: 'stress_eating',
      },
      {
        name: 'No time to cook',
        value: 'no_cookinng',
      },
      {
        name: 'Living with family',
        value: 'family',
      },
      {
        name: 'Health issues',
        value: 'health',
      },
      {
        name: 'Something else',
        value: 'other',
        showOther: true,
      },
    ],
  },
  {
    id: StepId.NUM_MEALS,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How many meals would you prefer to eat per day?',
    options: [
      {
        name: '2 meals',
        value: 2,
      },
      {
        name: '3 meals',
        value: 3,
      },
      {
        name: '4 meals (3 meals + 1 snack)',
        value: 4,
      },
      {
        name: '5 meals (3 meals + 2 snacks)',
        value: 5,
      },
    ],
  },
  {
    id: StepId.WATER,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How much water do you drink each day?',
    hint: '(Drinking water can help you reach your goals faster)',
    options: [
      {
        name: 'Less than 2 glasses (16 oz)',
        value: 1,
      },
      {
        name: '2-6 glasses (16 - 48oz)',
        value: 2,
      },
      {
        name: 'More than 6 glasses',
        value: 3,
      },
    ],
  },
  {
    id: StepId.CONTENT_SET_POINT,
    type: QuizStepType.CONTENT,
    content_type: 'html',
    image: '/measured-guarantee.svg',
    label: 'The Measured Guarantee',
    hint: "We stand by our product -- if you're not completely satisfied with your membership just let us know and we'll work with you to make things right.",
  },
]
