import Modal from '@components/Modal'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { stripeIntentApi } from '@utils/payments'
import Image from 'next/image'
import { useEffect, useState, useCallback } from 'react'

import StripeCheckoutForm from './Form'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setPaymentProcessorData, setProcessor } from 'redux/order'
import { selectLeadId, setUserInfo, setPhone, setName } from 'redux/user'
import {
  PaymentProcessorData,
  Plan,
  StripeCheckoutFormUserInfo,
  StripeCheckoutResponse,
  StripeIntentResponse,
} from '@types'
import { selectScreenLoading } from 'redux/screen'
import { selectStripeIntent, setStripeIntent } from 'redux/stripe'
import { useExperiment } from 'redux/experiment'
import { getEventId } from '@utils/tracking'

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY as string)

export const StripeCheckout = ({
  plan,
  onPaymentComplete,
}: {
  plan: Plan
  onPaymentComplete: () => void
}) => {
  const { experiment } = useExperiment()
  const dispatch = useAppDispatch()
  const railsLeadID = useAppSelector(selectLeadId)
  const [phoneHelp, setPhoneHelp] = useState(false)
  const { amount } = plan
  const [formDisabled, setFormDisabled] = useState<boolean>(true)
  const isLoading = useAppSelector(selectScreenLoading)
  const stripeIntent = useAppSelector(selectStripeIntent)

  const createOrUpdateStripePaymentIntent = useCallback(async () => {
    let response: StripeIntentResponse

    if (!stripeIntent) {
      response = await stripeIntentApi.create({ amount })
    } else {
      response = await stripeIntentApi.update({ id: stripeIntent.id, amount })
    }
    if (response.status) {
      setFormDisabled(false)
      dispatch(setStripeIntent(response.stripeIntent))
    }
  }, [amount, dispatch, stripeIntent])

  useEffect(() => {
    createOrUpdateStripePaymentIntent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const togglePhoneHelp = () => {
    setPhoneHelp(!phoneHelp)
  }

  const successfulPayment = async (
    result: StripeCheckoutResponse,
    userInfo: StripeCheckoutFormUserInfo,
  ) => {
    const {
      phone,
      email,
      name,
      customerId,
      paymentId,
      amount,
      paymentMethodId,
      subscriptionId,
      purchased,
      paymentEmail,
    } = result

    await dispatch(setProcessor('stripe'))
    await dispatch(setUserInfo(userInfo))
    await dispatch(setName(name))
    await dispatch(setPhone(phone))

    const paymentProcessorData: PaymentProcessorData = {
      name,
      email,
      phone,
      customerId,
      paymentId,
      paymentMethodId,
      subscriptionId,
      trial: false,
      selectedPlan: plan,
      purchased,
      paymentEmail,
      purchaseEventId: getEventId('Purchase'),
      leadId: railsLeadID as string,
      totalPaid: amount,
      referrer: window.location.href,
      funnel: experiment?.funnel || '8AM-pt1',
      flags: ['async'],
    }
    dispatch(setPaymentProcessorData({ ...paymentProcessorData }))

    // await dispatch(setPurchaseOrderId(result.subscription.purchaseOrderId))
    console.log('Created invoice and set default payment method')

    onPaymentComplete()
  }

  return (
    <div
      className={
        formDisabled ? 'stripe-checkout submitting' : 'stripe-checkout'
      }
    >
      {phoneHelp && (
        <Modal
          showModal={true}
          toggleModal={togglePhoneHelp}
          modalClass="modal-phone-hint"
          modalTitle="Your Privacy"
        >
          <div className="text-center p-4">
            <p className="mt-1 my-4">
              We value your privacy and only use your phone number to verify
              billing details and ensure product delivery. It will never be sold
              to a third party.
            </p>
            <button
              onClick={togglePhoneHelp}
              className="bg-brand-primary-4-default hover:bg-brand-primary-4-lighter text-white rounded px-x py-2 block w-full font-semibold"
            >
              Close
            </button>
          </div>
        </Modal>
      )}
      {stripeIntent && (
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm
            plan={plan}
            togglePhoneHelp={togglePhoneHelp}
            formDisabled={formDisabled}
            setFormDisabled={disabled => setFormDisabled(disabled)}
            clientSecret={stripeIntent.client_secret as string}
            postPayment={successfulPayment}
          />
        </Elements>
      )}
      {isLoading && (
        <div className="loading w-full h-full flex flex-col items-center justify-center">
          <Image alt="Loading" src="/loading.gif" height="100" width="100" />
          <h3 className="quiz-body">Processing...</h3>
          <p className="quiz-heading-2xl max-w-md py-10">
            Please do not reload or press the back button.
          </p>
        </div>
      )}
      <div className="success text-center">
        <div className="mx-auto mb-5">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <h3 className="title mt-3" data-tid="success.title">
          Payment successful
        </h3>
        <p className="message text-lg">
          <span data-tid="success.message">
            Thank you for your purchase! This page should be redirecting now...
          </span>
        </p>
      </div>
    </div>
  )
}

export default StripeCheckout
