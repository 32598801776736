import { useAppSelector } from '../redux/hooks'
import { selectEmail } from '../redux/user'
import { useEffect, ReactNode, useState } from 'react'
import { useRouter } from 'next/router'
import { FacebookPixel } from '@components/Scripts/FacebookPixel'
import { MixpanelTracker } from '@components/Scripts/MixpanelTracker'
import GoogleAnalytics from '@components/Scripts/GoogleAnalytics'
import Outbrain from '@components/Scripts/Outbrain'
import Criteo, { criteoHomepageEvent } from '@components/Scripts/Criteo'
import DatadogRum from '@components/Scripts/DatadogRum'
import { getExperimentIdRoute } from 'experiments/utils'

const scripts = {
  facebookPixel: false,
  mixpanel: false,
  googleAnalytics: false,
  helloBar: false,
  outbrain: false,
  criteo: false,
  datadogRum: false,
}

export default function Tracking({ children }: { children: ReactNode }) {
  const router = useRouter()
  const email = useAppSelector(selectEmail)
  const [loaded, setLoaded] = useState(scripts)
  const [firedInitialEvent, setFiredInitialEvent] = useState(scripts)

  const setLoadedScript = (name: string) => {
    // console.log('loaded', name)
    setLoaded({ ...loaded, [name]: true })
  }

  const registerHomepageEvent = (name: string) => {
    // console.log('fired initial event', name)
    setFiredInitialEvent({ ...firedInitialEvent, [name]: true })
  }

  const isHomepage = (url: string) => {
    if (
      url === '/' ||
      url === '/quiz?step=1' ||
      url === '/quiz/start-female' ||
      url === '/quiz/start-male'
    ) {
      return true
    } else {
      return false
    }
  }

  // Track client-side page views.
  const handleRouteChange = (url: string) => {
    if (email) {
      // TODO - Implement Customer.io tracking
    }

    // Events that only need firing once per session
    if (isHomepage(url)) {
      if (loaded.facebookPixel && !firedInitialEvent.facebookPixel) {
        window?.ReactPixel?.track('ViewContent')
        registerHomepageEvent('facebookPixel')
      }

      if (loaded.criteo && !firedInitialEvent.criteo) {
        criteoHomepageEvent()
        registerHomepageEvent('criteo')
      }
    }
  }

  // On initial page load.
  useEffect(() => {
    handleRouteChange(router?.asPath || '')

    if (typeof window !== 'undefined') {
      // get subdomain from url and check if under the *-staging experiment environment
      const subdomain = getExperimentIdRoute(
        window.location.hostname.split('.')[0],
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, router.events, router.asPath])

  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && (
        <GoogleAnalytics setLoadedScript={setLoadedScript} />
      )}
      {process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID && (
        <FacebookPixel setLoadedScript={setLoadedScript} />
      )}
      {process.env.NEXT_PUBLIC_MIXPANEL_API_KEY && loaded?.mixpanel && (
        <MixpanelTracker />
      )}
      {process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN && (
        <DatadogRum setLoadedScript={setLoadedScript} />
      )}
      {process.env.NEXT_PUBLIC_OUTBRAIN_ID && (
        <Outbrain setLoadedScript={setLoadedScript} />
      )}
      {process.env.NEXT_PUBLIC_CRITEO_ID && (
        <Criteo setLoadedScript={setLoadedScript} />
      )}
      {children}
    </>
  )
}
