import { Experiment } from '@types'
import { questions } from '@data/questions/glp'
import CheckoutPage from 'pages/checkout'
import Confirmation from 'pages/confirmation'
import Results from 'pages/results'
import YourPlan from 'pages/your-plan'
import QuizPage from 'pages/quiz'
import WhatsNextPage from 'pages/whats-next'
import plans, { defaultPlan } from '@data/plans/pt1'
import dictionary from './dictionary'
import PlanDetailsCard from '@components/Plan/PlanDetailsCard'
import MedicalPlanInfo from '@components/Plan/MedicalPlanInfo'

const glp: Experiment = {
  id: 'glp',
  name: 'Measured - Modern Weight Loss',
  description:
    'Measured uses modern medicine and personalized care plans to help you lose weight and keep it off.',
  questions,
  resultsPage: Results,
  planPage: YourPlan,
  whatsNextPage: WhatsNextPage,
  planPageBoxes: [
    <PlanDetailsCard
      key={1}
      title={'GLP-1 Prescription Medication'}
      description={'Sent to your local pharmacy'}
      MoreInfo={MedicalPlanInfo}
      image={'/your-prescription-medications.png'}
    />,
    <PlanDetailsCard
      key={2}
      title="Measured Care™"
      features={[
        `Access to licensed clinician`,
        `Insurance claim assistance`,
        `Personalized nutrition guide`,
        `Health coaching`,
      ]}
      image={'/health-coach.svg'}
    />,
  ],
  checkoutPage: CheckoutPage,
  confirmationPage: Confirmation,
  questionsPage: QuizPage,
  flowOrder: [
    'questionsPage',
    'resultsPage',
    'planPage',
    'whatsNextPage',
    'checkoutPage',
    'confirmationPage',
  ],
  funnel: 'GLP1',
  default: false,
  helloBarId: '8aa86db97237e4ed4e2bc8a36c2d477478381bc5',
  oneTimePurchase: true,
  plans: { defaultPlan, ...plans },
  showPlanSelector: false,
  dictionary: dictionary,
}

export default glp
