import classNames from 'classnames'

const GuaranteeBlock = ({ size = 'large' }: any) => {
  return (
    <div
      className={classNames({
        'bg-secondary1/5 rounded-xl mb-8 md:mx-auto px-4 md:px-8 md:w-full w-full':
          true,
        'py-10 mb-10 md:mb-16': size === 'large' || size === 'small',
        'py-4 pb-8 mt-4': size === 'xs',
      })}
    >
      <div
        className={classNames({
          'flex flex-col space-x-0 md:space-x-4 md:flex-row md:items-center':
            size === 'large' || size === 'small',
          'flex flex-col md:items-center text-center': size === 'xs',
        })}
      >
        <h3 className="quiz-heading-2xl mb-4">Satisfaction Guarantee</h3>
        <p className="quiz-paragraph-sm">
          If for any reason you&#39;re not happy with our products or services,
          please let us know, and we&#39;ll do everything we can to make things
          right for you. Our memberships are month-to-month and can be canceled
          at any time.
        </p>
      </div>
    </div>
  )
}

export default GuaranteeBlock
