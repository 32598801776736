import Checkout from '@components/Checkout'
import CheckoutPT1 from '@components/pt1/Checkout'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { selectEmail } from '../redux/user'
import { setLoading } from '../redux/screen'
import Router from 'next/router'
import { useEffect } from 'react'
import { trackEvent } from '@utils/tracking'
import Modal from '@components/Modal'
import {
  selectPurchaseComplete,
  setPurchaseComplete,
  setPurchased,
} from '../redux/order'
import { useExperiment } from 'redux/experiment'
import { trackKlaviyoEvent } from '@components/Scripts/Klaviyo'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'

export default function CheckoutPage() {
  const dispatch = useAppDispatch()
  const purchaseComplete = useAppSelector(selectPurchaseComplete)
  const email = useAppSelector(selectEmail)
  const { experiment } = useExperiment()
  const oneTimePurchase = experiment?.oneTimePurchase

  useEffect(() => {
    trackMixpanelEvent('Checkout page', experiment?.funnel, { email })
    trackEvent({ eventName: 'InitiateCheckout' })
    trackKlaviyoEvent('Started Checkout', experiment?.funnel, email)
  }, [email, experiment?.funnel])

  const onPaymentComplete = () => {
    dispatch(setLoading(true))
    return Router.push('/confirmation')
  }

  const clearPaymentComplete = () => {
    dispatch(setPurchased([]))
    dispatch(setPurchaseComplete(false))
  }

  const modal = (
    <Modal showModal={purchaseComplete} modalTitle={'Wait..'}>
      <>
        <div className="text-lg px-4 pb-4">
          <p className="my-4">
            Our records show you have already purchased a meal plan. You will
            receive an email confirmation 4-5 minutes after ordering.
          </p>
          <p className="my-4">
            To access your meal plan, please visit:{' '}
            <a
              href="https://app.trymeasured.com/"
              className="text-brand-primary-4-darker underline"
            >
              https://app.trymeasured.com/
            </a>
          </p>
          <button
            onClick={clearPaymentComplete}
            className="action-button dark-button medium"
          >
            Purchase Another Plan &rarr;
          </button>
        </div>
      </>
    </Modal>
  )

  return (
    <div className="max-w-lg w-full mx-auto">
      {modal}
      {oneTimePurchase ? (
        <CheckoutPT1 onPaymentComplete={onPaymentComplete} />
      ) : (
        <Checkout onPaymentComplete={onPaymentComplete} />
      )}
    </div>
  )
}
