import { ZipCode } from '@types'

const zipCodesByState: ZipCode[] = [
  { id: 6, name: 'Alaska', st: 'AK', zipMin: 99501, zipMax: 99950 },
  { id: 5, name: 'Alabama', st: 'AL', zipMin: 35004, zipMax: 36925 },
  { id: 69, name: 'Arkansas', st: 'AR', zipMin: 71601, zipMax: 72959 },
  {
    id: 8,
    name: 'Arkansas (Texarkana)',
    st: 'AR',
    zipMin: 75502,
    zipMax: 75502,
  },
  { id: 7, name: 'Arizona', st: 'AZ', zipMin: 85001, zipMax: 86556 },
  { id: 9, name: 'California', st: 'CA', zipMin: 90001, zipMax: 96162 },
  { id: 10, name: 'Colorado', st: 'CO', zipMin: 80001, zipMax: 81658 },
  { id: 11, name: 'Connecticut', st: 'CT', zipMin: 6001, zipMax: 6389 },
  { id: 73, name: 'Connecticut', st: 'CT', zipMin: 6401, zipMax: 6928 },
  { id: 13, name: 'Dist of Columbia', st: 'DC', zipMin: 20001, zipMax: 20039 },
  { id: 74, name: 'Dist of Columbia', st: 'DC', zipMin: 20042, zipMax: 20599 },
  { id: 63, name: 'Dist of Columbia', st: 'DC', zipMin: 20799, zipMax: 20799 },
  { id: 12, name: 'Delaware', st: 'DE', zipMin: 19701, zipMax: 19980 },
  { id: 14, name: 'Florida', st: 'FL', zipMin: 32004, zipMax: 34997 },
  { id: 15, name: 'Georgia', st: 'GA', zipMin: 30001, zipMax: 31999 },
  { id: 65, name: 'Georga (Atlanta)', st: 'GA', zipMin: 39901, zipMax: 39901 },
  { id: 16, name: 'Hawaii', st: 'HI', zipMin: 96701, zipMax: 96898 },
  { id: 20, name: 'Iowa', st: 'IA', zipMin: 50001, zipMax: 52809 },
  { id: 66, name: 'Iowa (OMAHA)', st: 'IA', zipMin: 68119, zipMax: 68120 },
  { id: 17, name: 'Idaho', st: 'ID', zipMin: 83201, zipMax: 83876 },
  { id: 18, name: 'Illinois', st: 'IL', zipMin: 60001, zipMax: 62999 },
  { id: 19, name: 'Indiana', st: 'IN', zipMin: 46001, zipMax: 47997 },
  { id: 21, name: 'Kansas', st: 'KS', zipMin: 66002, zipMax: 67954 },
  { id: 22, name: 'Kentucky', st: 'KY', zipMin: 40003, zipMax: 42788 },
  { id: 68, name: 'Louisiana', st: 'LA', zipMin: 70001, zipMax: 71232 },
  { id: 23, name: 'Louisiana', st: 'LA', zipMin: 71234, zipMax: 71497 },
  { id: 26, name: 'Massachusetts', st: 'MA', zipMin: 1001, zipMax: 2791 },
  {
    id: 58,
    name: 'Massachusetts (Andover)',
    st: 'MA',
    zipMin: 5501,
    zipMax: 5544,
  },
  { id: 77, name: 'Maryland', st: 'MD', zipMin: 20331, zipMax: 20331 },
  { id: 78, name: 'Maryland', st: 'MD', zipMin: 20335, zipMax: 20797 },
  { id: 25, name: 'Maryland', st: 'MD', zipMin: 20812, zipMax: 21930 },
  { id: 24, name: 'Maine', st: 'ME', zipMin: 3901, zipMax: 4992 },
  { id: 27, name: 'Michigan', st: 'MI', zipMin: 48001, zipMax: 49971 },
  { id: 28, name: 'Minnesota', st: 'MN', zipMin: 55001, zipMax: 56763 },
  { id: 57, name: 'kc96 DataMO', st: 'MO', zipMin: 63001, zipMax: 65899 },
  { id: 29, name: 'Mississippi', st: 'MS', zipMin: 38601, zipMax: 39776 },
  {
    id: 59,
    name: 'Mississippi(Warren)',
    st: 'MS',
    zipMin: 71233,
    zipMax: 71233,
  },
  { id: 31, name: 'Montana', st: 'MT', zipMin: 59001, zipMax: 59937 },
  { id: 38, name: 'North Carolina', st: 'NC', zipMin: 27006, zipMax: 28909 },
  { id: 39, name: 'North Dakota', st: 'ND', zipMin: 58001, zipMax: 58856 },
  { id: 67, name: 'Nebraska', st: 'NE', zipMin: 68001, zipMax: 68118 },
  { id: 32, name: 'Nebraska', st: 'NE', zipMin: 68122, zipMax: 69367 },
  { id: 34, name: 'New Hampshire', st: 'NH', zipMin: 3031, zipMax: 3897 },
  { id: 35, name: 'New Jersey', st: 'NJ', zipMin: 7001, zipMax: 8989 },
  { id: 36, name: 'New Mexico', st: 'NM', zipMin: 87001, zipMax: 88441 },
  { id: 33, name: 'Nevada', st: 'NV', zipMin: 88901, zipMax: 89883 },
  {
    id: 60,
    name: 'New York (Fishers Is)',
    st: 'NY',
    zipMin: 6390,
    zipMax: 6390,
  },
  { id: 37, name: 'New York', st: 'NY', zipMin: 10001, zipMax: 14975 },
  { id: 40, name: 'Ohio', st: 'OH', zipMin: 43001, zipMax: 45999 },
  { id: 70, name: 'Oklahoma', st: 'OK', zipMin: 73001, zipMax: 73199 },
  { id: 41, name: 'Oklahoma', st: 'OK', zipMin: 73401, zipMax: 74966 },
  { id: 42, name: 'Oregon', st: 'OR', zipMin: 97001, zipMax: 97920 },
  { id: 43, name: 'Pennsylvania', st: 'PA', zipMin: 15001, zipMax: 19640 },
  { id: 45, name: 'Rhode Island', st: 'RI', zipMin: 2801, zipMax: 2940 },
  { id: 46, name: 'South Carolina', st: 'SC', zipMin: 29001, zipMax: 29948 },
  { id: 47, name: 'South Dakota', st: 'SD', zipMin: 57001, zipMax: 57799 },
  { id: 48, name: 'Tennessee', st: 'TN', zipMin: 37010, zipMax: 38589 },
  { id: 49, name: 'Texas (Austin)', st: 'TX', zipMin: 73301, zipMax: 73301 },
  { id: 71, name: 'Texas', st: 'TX', zipMin: 75001, zipMax: 75501 },
  { id: 72, name: 'Texas', st: 'TX', zipMin: 75503, zipMax: 79999 },
  { id: 61, name: 'Texas (El Paso)', st: 'TX', zipMin: 88510, zipMax: 88589 },
  { id: 50, name: 'Utah', st: 'UT', zipMin: 84001, zipMax: 84784 },
  { id: 64, name: 'Virginia', st: 'VA', zipMin: 20040, zipMax: 20041 },
  { id: 75, name: 'Virginia', st: 'VA', zipMin: 20040, zipMax: 20167 },
  { id: 76, name: 'Virginia', st: 'VA', zipMin: 20042, zipMax: 20042 },
  { id: 52, name: 'Virginia', st: 'VA', zipMin: 22001, zipMax: 24658 },
  { id: 51, name: 'Vermont', st: 'VT', zipMin: 5001, zipMax: 5495 },
  { id: 62, name: 'Vermont', st: 'VT', zipMin: 5601, zipMax: 5907 },
  { id: 53, name: 'Washington', st: 'WA', zipMin: 98001, zipMax: 99403 },
  { id: 55, name: 'Wisconsin', st: 'WI', zipMin: 53001, zipMax: 54990 },
  { id: 54, name: 'West Virginia', st: 'WV', zipMin: 24701, zipMax: 26886 },
  { id: 56, name: 'Wyoming', st: 'WY', zipMin: 82001, zipMax: 83128 },
]

export const getStateByZipCode = (zipCode: number) =>
  zipCodesByState.find(zip => zipCode >= zip.zipMin && zipCode <= zip.zipMax)
    ?.st

export const getMinZipCode = () =>
  Math.min.apply(
    Math,
    zipCodesByState.map(d => d.zipMin),
  )

export const getMaxZipCode = () =>
  Math.max.apply(
    Math,
    zipCodesByState.map(d => d.zipMax),
  )
