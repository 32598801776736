import Image from 'next/image'
import { Purchased } from '@types'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  selectProcessor,
  selectSelectedPlan,
  setPurchased,
} from '../../redux/order'
import { clearStripeIntent } from '../../redux/stripe'
import { selectEmail, selectPhone } from '../../redux/user'
import { trackEvent } from '@utils/tracking'
import StripeCheckout from './Stripe'
import GuaranteeBlock from '@components/Results/GuaranteeBlock'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'
import OrderSummary from './OrderSummary'
import { useExperiment } from 'redux/experiment'
import { trackKlaviyoEvent } from '@components/Scripts/Klaviyo'

const Checkout = ({
  onPaymentComplete,
}: {
  onPaymentComplete: (purchased: Purchased[]) => void
}) => {
  const dispatch = useAppDispatch()
  const selectedPlan = useAppSelector(state => selectSelectedPlan(state))
  const email = useAppSelector(selectEmail)
  const phone = useAppSelector(selectPhone)
  const processor = useAppSelector(selectProcessor)
  const { experiment } = useExperiment()

  const paymentCompleteHandler = () => {
    const purchased: Purchased[] = [
      {
        ...selectedPlan,
        name: selectedPlan.productName,
        price: selectedPlan.salePrice,
        variant: '',
        quantity: 1,
        id: selectedPlan.sku,
      },
    ]
    try {
      console.log(
        `Firing purchase pixel from ${processor} $`,
        selectedPlan.salePrice,
      )

      trackMixpanelEvent('Purchase', experiment?.funnel, {
        sku: selectedPlan?.sku,
        value: selectedPlan?.salePrice,
        currency: 'USD',
        quantity: 1,
        email,
        phone,
      })

      trackEvent({
        eventName: 'Purchase',
        options: {
          value: selectedPlan.salePrice,
          currency: 'USD',
        },
        advancedMatchingOptions: {
          em: email as string,
          ph: phone as string,
        },
      })
      trackKlaviyoEvent('Placed order - Purchase', experiment?.funnel, email, {
        value: selectedPlan.salePrice,
        currency: 'USD',
      })

      const dataLayer = window.dataLayer || []
      dataLayer.push({
        event: 'start-purchase',
        eventCategory: 'checkout',
        eventAction: 'Start Purchase Flow',
        eventLabel: `Start Purchase Flow`,
      })
    } catch (error) {
      console.log(error)
    }

    dispatch(setPurchased(purchased))
    dispatch(clearStripeIntent())

    onPaymentComplete(purchased)
  }
  return (
    <>
      <OrderSummary />
      <StripeCheckout
        plan={selectedPlan}
        onPaymentComplete={paymentCompleteHandler}
      />
      <div className="text-secondary1/60 text-center italic text-gray-600 px-6 guarantee flex flex-col sm:flex-row items-center justify-center max-w-xl text-sm mx-auto">
        Your membership begins on the day of payment and auto-renews at $
        {selectedPlan.salePrice} every{' '}
        {selectedPlan.duration === 1
          ? 'month'
          : `${selectedPlan.duration} months`}
        .
      </div>
      <div className="text-center flex items-center justify-center space-x-2 my-3 text-secondary1/60">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 -mt-0.5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
        <p>
          Guaranteed <span className="font-bold">safe &amp; secure</span>{' '}
          checkout.
        </p>
      </div>
      <div
        className="relative w-full h-10 px-4 mx-auto mb-2"
        style={{ maxWidth: 450 }}
      >
        <Image
          alt="Stripe Checkout"
          src="/credit-cards.png"
          layout="fill"
          objectFit="contain"
        />
      </div>
      <GuaranteeBlock size="xs" />
    </>
  )
}

export default Checkout
