import { FunctionComponent, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectProgressPercent } from 'redux/quiz/steps'

interface ProgressProps {
  showProgress?: boolean
}
const Progress: FunctionComponent<ProgressProps> = ({ showProgress }) => {
  const progress = useSelector(selectProgressPercent)
  const progressRef = useRef(0)

  useEffect(() => {
    if (progress !== null) {
      progressRef.current = progress
    }
  }, [progress])

  return !showProgress ? null : (
    <div className="progress-bar w-full mx-auto">
      <div
        className={
          progressRef.current && progressRef.current < 100
            ? 'rounded-r-full'
            : ''
        }
        style={{ width: `${progressRef.current}%` }}
      ></div>
    </div>
  )
}
export default Progress
