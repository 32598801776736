import { useExperiment } from 'redux/experiment'

export default function TreatmentPlan({
  hideTitle = false,
  isCheckout = false,
}: {
  hideTitle?: boolean
  isCheckout?: boolean
}) {
  const { experiment } = useExperiment()

  return (
    <div>
      {!hideTitle && <h1 className="quiz-heading">Your Treatment Plan</h1>}

      <div
        className={`your-plan-container pt1 ${
          isCheckout ? 'checkout-page' : ''
        }`}
      >
        <div className="cards">
          {experiment?.planPageBoxes?.map(Box => Box)}
        </div>
      </div>
    </div>
  )
}
