import { Track } from '@types'

const medicalSlides = [
  {
    title: 'Complete quiz & consultation',
    description:
      'Take a quiz about your current symptoms and basic health history, and then a clinician will review your information and schedule an initial consultation.',
    checkmarks: [
      'Just 2-3 minutes to complete quiz',
      'No waiting room — next day appointments',
      'At home or in-person lab testing',
    ],
  },
  {
    title: 'Get your treatment plan',
    description:
      'Get your easy to follow personalized treatment plan that includes a health coach, meal guide and prescription medication.',
    checkmarks: [
      'Developed by leading obesity physicians and dietitians',
      'GLP1 and Generic Medications',
      'Prescriptions sent to you local pharmacy',
    ],
  },
  {
    title: 'Get results & ongoing care',
    description:
      'Get support and education from our health coaches and platform, and become a part of Measured community.',
    checkmarks: [
      'Unlimited messaging with your coach and clinician',
      'Continuous lab testing to track your progress',
      'Medication updates and titrations on an ongoing basis',
    ],
  },
]

export const getSlides = (track: Track) => {
  if (track === Track.BEHAVIORAL) {
    medicalSlides[0].title = 'Complete quiz'
    medicalSlides[0].description =
      'Take a quiz about your current symptoms and basic health history.'
    medicalSlides[0].checkmarks = [medicalSlides[0].checkmarks[0]]

    medicalSlides[1].description =
      'Get your easy to follow personalized treatment plan that includes a health coach and meal guide.'
    medicalSlides[1].checkmarks = [medicalSlides[1].checkmarks[0]]

    medicalSlides[2].checkmarks = ['Unlimited messaging with your coach']
  }
  return medicalSlides
}
