import { Plan } from '@types'
import NumberFormat from 'react-number-format'

const calculateDiscount = (selectedPlan: Plan) =>
  Math.round(100 - (selectedPlan.salePrice / selectedPlan.originalPrice) * 100)

export const DiscountLine = ({ selectedPlan }: { selectedPlan: Plan }) => {
  const discountAmount = calculateDiscount(selectedPlan)

  if (
    !discountAmount ||
    selectedPlan.duration < 1 ||
    !selectedPlan.showSavedPercent
  ) {
    return null
  }

  return (
    <div className="flex flex-row justify-stretch">
      <div>
        <span className="font-medium text-red-600">Discount</span> &mdash;{' '}
        <span className="italic">{discountAmount}% off</span>
      </div>
      <div className="flex-1" />
      <div className="text-right">
        <div className="text-red-600">
          <NumberFormat
            displayType={'text'}
            value={
              -Math.round((selectedPlan.originalPrice * discountAmount) / 100)
            }
            prefix="$"
          />
        </div>
      </div>
    </div>
  )
}

export const DiscountInlineText = ({
  selectedPlan,
}: {
  selectedPlan: Plan
}) => {
  const discountAmount = calculateDiscount(selectedPlan)

  if (
    !discountAmount ||
    selectedPlan.duration < 1 ||
    !selectedPlan.showSavedPercent
  ) {
    return (
      <NumberFormat
        displayType={'text'}
        value={selectedPlan.originalPrice}
        prefix="$"
      />
    )
  }

  return (
    <div className="flex font-serif gap-2">
      <span className="line-through font-normal text-secondary2/25">
        <NumberFormat
          displayType={'text'}
          value={selectedPlan.originalPrice}
          prefix="$"
        />
      </span>
      <span className="">
        <NumberFormat
          displayType={'text'}
          value={selectedPlan.salePrice}
          prefix="$"
        />
      </span>
    </div>
  )
}

export const DiscountCustomText = ({
  originalPrice,
  discountAmount,
}: {
  originalPrice: number
  discountAmount: number
}) => {
  return (
    <div className="flex font-serif gap-2">
      <span className="line-through font-normal text-secondary2/25">
        <NumberFormat displayType={'text'} value={originalPrice} prefix="$" />
      </span>
      <span className="">
        <NumberFormat
          displayType={'text'}
          value={
            originalPrice + -Math.round((originalPrice * discountAmount) / 100)
          }
          prefix="$"
        />
      </span>
    </div>
  )
}
