import { defaultPlan } from '@data/plans/pt1'
import OrderSummaryRx from './OrderSummaryRx'

const OrderSummary = () => {
  const selectedPlan = defaultPlan()

  return <OrderSummaryRx selectedPlan={selectedPlan} />
}

export default OrderSummary
