import { useAppSelector } from '../redux/hooks'
import { selectTrack } from '../redux/user'
import TreatmentPlan from '@components/YourPlan/TreatmentPlan'
import SelectPlan from '@components/YourPlan/SelectPlan'
import BeforeAndAfter from '@components/YourPlan/BeforeAndAfter'
import HowMeasuredWorks from '@components/YourPlan/HowMeasuredWorks'
import Script from 'next/script'
import FounderNote from '@components/YourPlan/FounderNote'
import ReadyToSeeResults from '@components/YourPlan/ReadyToSeeResults'
import Footer from '@components/YourPlan/Footer'
import { useCallback, useEffect, useRef } from 'react'
import { GoodQuestionToAsk } from '@components/YourPlan/GoodQuestionToAsk'
import { useExperiment } from 'redux/experiment'
import { ExperimentPage } from '@types'
import { trackKlaviyoEvent } from '@components/Scripts/Klaviyo'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'

const { NEXT_PUBLIC_HOTJAR_ID } = process.env

export default function YourPlan() {
  const track = useAppSelector(selectTrack)
  const selectPlanRef = useRef<HTMLDivElement>(null)
  const { experiment, goNextRouteFlow } = useExperiment()
  const showPlanSelector = experiment?.showPlanSelector
  const currentPage = ExperimentPage.PLAN
  const email = useAppSelector(state => state.user.email)

  useEffect(() => {
    trackMixpanelEvent('Treatment plan page', experiment?.funnel)
    trackKlaviyoEvent('AddToCart', experiment?.funnel, email, {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollToSelectPlan = useCallback(() => {
    window.scroll({
      top: (selectPlanRef.current?.offsetTop || 0) - 150,
      behavior: 'smooth',
    })
  }, [])

  const onClick = () => {
    if (showPlanSelector) {
      scrollToSelectPlan()
    } else {
      goNextRouteFlow(currentPage)
      // return Router.push('/checkout')
    }
  }

  return (
    <>
      <Script id="show-banner">
        {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
      <div className="max-w-lg w-full mx-auto">
        <div className="mb-8 md:mb-12">
          <TreatmentPlan />
        </div>
        <div ref={selectPlanRef} className="mb-10">
          <SelectPlan onClick={onClick} />
        </div>
        <div className="mb-20">
          <BeforeAndAfter subtitle={'81% of members saw an improvement!'} />
        </div>
      </div>
      <div className="w-screen">
        <div className="max-w-lg w-full mx-auto px-4 sm:px-0">
          <HowMeasuredWorks track={track} onClick={onClick} />
        </div>
      </div>
      <div className="w-screen">
        <GoodQuestionToAsk />
      </div>
      <div className="w-screen bg-secondary1/5 py-9">
        <FounderNote />
      </div>
      <div className="w-screen bg-brand-primary-1-default">
        <div className="max-w-lg w-full mx-auto px-4 sm:px-0 py-10">
          <ReadyToSeeResults onClick={onClick} />
        </div>
      </div>
      <div className="w-screen bg-secondary1/5">
        <div className="max-w-lg w-full mx-auto px-4 sm:px-0">
          <Footer />
        </div>
      </div>
    </>
  )
}
