import { Plan, Track } from '@types'
import { initialDrConsutation } from '@data/plans/pt1'
import OrderPlanItem from './OrderPlanItem'
import NumberFormat from 'react-number-format'

const OrderSummaryRx = ({ selectedPlan }: { selectedPlan: Plan }) => {
  return (
    <div className="order-summary-wrapper">
      <div className="order-summary">
        <OrderPlanItem
          title="Price Today:"
          imagePath="/order-summary-rx/virtual-visit.jpg"
          selectedPlan={initialDrConsutation}
        />
        <OrderPlanItem
          title="Pay After Visit:"
          showCoupon
          selectedPlan={selectedPlan}
          whatIsIncluded={Track.MEDICAL}
          isCheckout
          hint="You will only be charged once you have reviewed and accepted your care plan with your clinician. Your membership is month-to-month, and you can cancel anytime"
        />
        <div className="order-total-today">
          <span className="font-medium tracking-wide">Total Due today</span>
          <div className="text-right font-medium text-xl">
            <NumberFormat
              displayType="text"
              value={
                initialDrConsutation.salePrice % 1 === 0
                  ? initialDrConsutation.salePrice
                  : initialDrConsutation.salePrice.toFixed(2)
              }
              prefix="$"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummaryRx
