import { useState, useMemo, useEffect } from 'react'
import { TextQuizStep, StepComponentProps } from '../../../@types'
import Actions from '@components/quiz/actions'

type Props = StepComponentProps<TextQuizStep, string>

export default function Text(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props
  const [result, setResult] = useState(value)
  const nextEnabled = useMemo(() => !!result, [result])

  useEffect(() => {
    setResult(value)
  }, [value])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResult(event.target.value)
  }

  const nextHandler = () => {
    onChange(step.id, result)
  }

  return (
    <>
      <div className="quiz-text-input-container mx-auto">
        <input
          onChange={handleInputChange}
          required
          type="text"
          value={result || ''}
          name={step.id}
          id={step.id}
          className="text-center quiz-input"
          placeholder={step.placeholder}
        />
        {step.units && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500" id={`${step.id}-units`}>
              {step.units}
            </span>
          </div>
        )}
      </div>
      <Actions
        onNext={nextHandler}
        nextEnabled={nextEnabled}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </>
  )
}
