import { Experiment } from '@types'
import glp from '../glp'

const priority: Experiment = {
  ...glp,
  id: 'priority',
  funnel: 'funnel/affiliate-priority',
  default: false,
}

export default priority
