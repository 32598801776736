import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Pill from '@icons/pill.svg'
import ContinueButton from '@components/ContinueButton'
import { useExperiment } from 'redux/experiment'

export default function MedicalPlanInfo({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (state: boolean) => void
}) {
  const { experiment } = useExperiment()
  let medications = [
    'Metformin',
    'Naltrexone',
    'GLP-1s',
    'Ozempic',
    'Wegovy',
    'Mounjaro',
  ]

  if (experiment?.id && experiment?.id === 'glp') {
    medications = ['Wegovy', 'Ozempic', 'Saxenda', 'Mounjaro', 'Other GLPs']
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="mx-auto text-sm rounded-lg py-4 px-3">
                  {experiment?.copies?.MedicalPlanInfo?.description || (
                    <>
                      <h3 className="quiz-heading-lg mb-3">
                        Medications We May Prescribe
                      </h3>
                      <p className="text-secondary1 opacity-60 left mb-4">
                        The exact medication your Measured doctor prescribes is
                        based on your lab results, biology and medical history.
                        These FDA-approved prescription medications can help you{' '}
                        <span className="font-semibold">control cravings</span>,{' '}
                        <span className="font-semibold">
                          stabilize blood sugar
                        </span>{' '}
                        and{' '}
                        <span className="font-semibold">suppress appetite</span>
                        .
                      </p>
                      <p className="text-secondary1 opacity-60 mb-4">
                        They may include but are not limited to:
                      </p>
                      <div className="text-secondary1 opacity-60 grid grid-cols-2 space-y-2 text-lg">
                        {medications.map(medication => (
                          <div
                            key={medication}
                            className="flex items-center justify-start space-x-2"
                          >
                            <Pill style={{ width: '30px', height: '30px' }} />
                            <span>{medication}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <ContinueButton
                    text="CLOSE"
                    size="small"
                    extraClasses="submit-button w-full mt-8"
                    enabled={true}
                    onClick={() => setOpen(false)}
                    showArrow={false}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
