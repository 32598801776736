import Modal from '@components/Modal'
import ContinueButton from '@components/ContinueButton'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { setCookie } from 'cookies-next'
import { selectPromoCode } from 'redux/order'
import { useSelector } from 'react-redux'

// const SHOW_IN_PATH = ['/your-plan', '/checkout']
const HIDE_IN_PATH = ['/confirmation', '/checkout']
const COOKIE_KEY_SAVE_UP_TO = 'keyQuizSaveUpToBanner'

export const SaveUpToBanner = () => {
  const [showModal, setShowModal] = useState(false)
  const [isClosed, setClosed] = useState<string | boolean>(false)
  const [discountApplied, setDiscountApplied] = useState(false)
  const [shouldRender, setShouldRender] = useState(false)
  const promoCode = useSelector(selectPromoCode)
  const { pathname } = useRouter()

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const closeTopBanner = () => {
    setCookie(
      COOKIE_KEY_SAVE_UP_TO,
      true,
      { maxAge: 60 * 60 }, // 1 hour
    )
    setClosed(true)
  }

  useEffect(() => {
    setShouldRender(
      !!promoCode && !isClosed && !HIDE_IN_PATH.includes(pathname),
    )
  }, [promoCode, pathname, isClosed])

  const updateTextAndClose = () => {
    setDiscountApplied(true)

    setTimeout(() => {
      toggleModal()
    }, 2000)
  }

  const discountValue = promoCode?.code === 'BLACKFRI23' ? '20%' : '$45'

  const defaultCopy = (
    <>
      <div className="font-serif text-2xl font-medium mb-4">Winter Special</div>
      <p className="mb-2 text-xl">
        Join today for only <strong className="text-red-600 italic">$39</strong>
      </p>
      <div className="mb-2 text-5xl italic flex items-center justify-center hidden">
        <div className="text-gray-300 h-5 px-2">~</div>
        <div>{discountValue} OFF</div>
        <div className="text-gray-300 h-5 pr-2">~</div>
      </div>
      <p className="mb-9 max-w-sm text-base">
        For a limited time, become a Measured member for only $39 for your first
        month. No commitment required.
      </p>
      <div className="max-w-xs mx-auto">
        <ContinueButton
          enabled={true}
          text="Apply Discount"
          size="large"
          extraClasses="shadow-pulse dark-button"
          onClick={updateTextAndClose}
        />
      </div>
    </>
  )

  const updatedCopy = (
    <>
      <p className="mb-3 text-2xl font-semibold italic font-serif">
        Discount Applied!
      </p>
      <p className="mb-9 text-lg px-8">
        Please continue to checkout to complete your order.
      </p>
      <div className="max-w-xs mx-auto">
        <ContinueButton
          enabled={true}
          text="Continue"
          size="large"
          extraClasses="shadow-pulse dark-button"
          onClick={toggleModal}
        />
      </div>
    </>
  )

  return !shouldRender ? null : (
    <>
      <Modal
        showModal={showModal}
        toggleModal={toggleModal}
        modalClass="modal-save-up-to"
        closeIconColor="text-white"
      >
        <div className="mx-auto py-2 text-xl flex flex-col items-center justify-center">
          {discountApplied ? updatedCopy : defaultCopy}
        </div>
      </Modal>
      <div
        className="banner"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <div className="banner-close" onClick={closeTopBanner}>
          &times;
        </div>
        <div>Winter Special 🎉 &mdash; Get started for only $39!</div>
      </div>
    </>
  )
}
