import { Plan } from '@types'
import NumberFormat from 'react-number-format'

export const CouponLine = ({
  selectedPlan,
  coupon,
}: {
  selectedPlan: Plan
  coupon: any
}) => {
  if (!coupon) {
    return null
  }

  return (
    <div className="flex flex-row justify-stretch">
      <div>
        <span className="font-medium text-red-600">Promo Code </span> &mdash;{' '}
        <span className="italic">{selectedPlan.valueText}</span>
      </div>
      <div className="flex-1" />
      <div className="text-right">
        <div className="text-red-600">
          <NumberFormat
            displayType={'text'}
            value={Math.round(
              (selectedPlan.valueAmount && -selectedPlan.valueAmount) || 0,
            ).toFixed(0)}
            prefix="$"
          />
        </div>
      </div>
    </div>
  )
}
