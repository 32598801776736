/* eslint-disable @next/next/no-img-element */

interface IHowMeasuredWorksSlideProps {
  index: number
  title: string
  description: string
  checkmarks: string[]
}

export const HowMeasuredWorksSlide = ({
  index,
  title,
  description,
  checkmarks,
}: IHowMeasuredWorksSlideProps) => {
  return (
    <div className="how-msrd-works-slide">
      <div className="slide-step-indx">STEP {index}</div>
      <p className="slide-title">{title}</p>
      <p className="slide-description">{description}</p>
      <div className="slide-checkmarks">
        {checkmarks.map((checkmark, indx) => (
          <div key={indx}>
            <img src="/checkmark.svg" alt="slide item" />
            <p>{checkmark}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
