/* eslint-disable @next/next/no-img-element */
import { useRef } from 'react'
import { goodQuestions } from './data'

export const GoodQuestionToAsk = () => {
  const goodQuestionContainer = useRef<HTMLDivElement>(null)
  const handleClick = (index: number) => {
    document
      .querySelectorAll('.good-question-body')
      .forEach((body, idx) => idx !== index && body.classList.remove('visible'))
    const items = goodQuestionContainer.current?.children
    const currentSelected = items?.[index]
    currentSelected
      ?.querySelector('.good-question-body')
      ?.classList.toggle('visible')

    window.scroll({
      top: (goodQuestionContainer.current?.offsetTop || 0) - 50,
      behavior: 'smooth',
    })
  }
  return (
    <div className="good-question-to-ask">
      <h1 className="quiz-heading-3xl mb-9">Good questions to ask.</h1>
      <div className="good-question-container" ref={goodQuestionContainer}>
        {goodQuestions.map((goodQuestion, index) => (
          <div
            className="good-question"
            key={index}
            onClick={() => handleClick(index)}
          >
            <div className="good-question-header">
              <h2>{goodQuestion.title}</h2>
              <div>
                <img src="/plus.svg" alt={goodQuestion.title} />
              </div>
            </div>
            <div className="good-question-body">{goodQuestion.content}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
