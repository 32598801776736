import WeightGraph from './WeightGraph'
import ContinueButton from '../ContinueButton'
import { ExperimentPage, Gender, Macros, Track } from '../../@types'
import { useExperiment } from 'redux/experiment'
import { useEffect } from 'react'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'

type ResultsProps = {
  currentWeight: number
  targetWeight: number
  gender: Gender
  progressAmount: number
  track: Track
  height: number
  macros: Macros
}
export default function Results({
  track = Track.BEHAVIORAL,
  currentWeight,
  targetWeight,
}: ResultsProps) {
  const { goNextRouteFlow, experiment } = useExperiment()
  const isMedical = track === Track.MEDICAL
  const onClick = () => {
    goNextRouteFlow(ExperimentPage.RESULTS, { track })
  }

  useEffect(() => {
    trackMixpanelEvent('Results/graph page', experiment?.funnel, {
      currentWeight,
      targetWeight,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mx-auto">
      <div className="mx-auto">
        <h2 className="quiz-heading">
          {experiment?.dictionary?.resultsPage?.title || (
            <>
              Your Personalized {isMedical && 'Medical'} Weight Loss Plan Is
              Ready
            </>
          )}
        </h2>
        <div className="quiz-paragraph">
          {experiment?.dictionary?.resultsPage?.paragraph || (
            <>
              Based on your answers, you can lose up to 6 lbs in your first
              month on your plan.
            </>
          )}
        </div>
        <div className="max-w-xl mx-auto mt-10">
          <WeightGraph
            currentWeight={currentWeight}
            targetWeight={targetWeight}
          />
        </div>

        <div className="action-container">
          <ContinueButton enabled text="See Your Plan" onClick={onClick} />
        </div>
      </div>
    </div>
  )
}
