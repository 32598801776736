import Loading from './Loading'

const ScreenLoading = ({ text = 'Loading...' }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center">
      <Loading text={text} />
      <div
        className="absolute top-0 left-0 right-0 bottom-0 bg-white"
        style={{ opacity: 0.9 }}
      />
    </div>
  )
}

export default ScreenLoading
