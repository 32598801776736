import confetti from 'canvas-confetti'

const defaultOptions = {
  origin: { y: 0.5 },
}

interface ConfettiProps {
  count?: number
  defaults?: confetti.Options
}

const fireConfetti = ({
  count = 200,
  defaults = defaultOptions,
}: ConfettiProps) => {
  const fire = (particleRatio: number, opts: confetti.Options) => {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      }),
    )
  }

  fire(0.25, {
    spread: 40,
    startVelocity: 55,
  })
  fire(0.2, {
    spread: 70,
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  })
}

export default fireConfetti
