import Actions from '@components/quiz/actions'
import { BirthdayStep, StepComponentProps } from '@types'
import { useEffect, useMemo, useRef, useState } from 'react'

type Props = StepComponentProps<
  BirthdayStep,
  { month?: number; year?: number; day?: number } | null
>

const Birthday: React.FC<Props> = (props: Props) => {
  const { value } = props
  const [result, setResult] = useState(value)
  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setResult(value)
  }, [value])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResult(getCurrentDate())
    if (event.target === monthRef.current) {
      if (monthRef.current.value.length === 2) {
        dayRef.current?.focus()
      }
    }
    if (event.target === dayRef.current) {
      if (dayRef.current.value.length === 2) {
        yearRef.current?.focus()
      }
    }
  }

  const nextHandler = () => {
    props.onChange(props.step.id, getCurrentDate())
  }

  const getCurrentDate = () => ({
    year: getValue(yearRef.current, 4),
    month: getValue(monthRef.current, 2),
    day: getValue(dayRef.current, 2),
  })

  const getValue = (input?: HTMLInputElement | null, maxDigits?: number) => {
    return input ? parseInt(input.value.substring(0, maxDigits)) : undefined
  }

  const nextEnabled = useMemo(
    () => !!result?.year && !!result?.month && !!result?.day,
    [result?.year, result?.month, result?.day],
  )

  return (
    <div className="max-w-2xl">
      <div className="quiz-text-input-container full-w max-w-xs mx-auto gap-2 items-center justify-center text-center md:max-w-sm">
        <input
          onChange={handleInputChange}
          ref={monthRef}
          required
          type="number"
          value={result?.month}
          className="text-center quiz-input"
          placeholder="MM"
          min="1"
          max="12"
          inputMode="numeric"
        />
        <div>-</div>
        <input
          onChange={handleInputChange}
          ref={dayRef}
          required
          type="number"
          value={result?.day}
          className="text-center quiz-input"
          placeholder="DD"
          min="1"
          max="31"
          inputMode="numeric"
        />
        <div>-</div>
        <input
          onChange={handleInputChange}
          ref={yearRef}
          required
          type="number"
          value={result?.year}
          className="text-center quiz-input"
          placeholder="YYYY"
          min="1900"
          max="2020"
          inputMode="numeric"
        />
      </div>
      <Actions
        onNext={nextHandler}
        nextEnabled={nextEnabled}
        isLastStep={props.isLastStep}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
      />
    </div>
  )
}

export default Birthday
