import Image from 'next/image'

export default function FounderNote() {
  return (
    <div className="max-w-lg w-full mx-auto px-4 sm:px-0">
      <h1 className="quiz-heading-2xl left mb-5 font-medium">
        A Note From Our Founders
      </h1>
      <div className="quiz-paragraph-lg antialiased">
        <p>
          Measured is on a mission to help people live healthier and happier
          lives by making weight loss personalized, approachable, and
          sustainable. We take a holistic approach—combining nutrition,
          movement, mindfulness, sleep, and in some cases, medicine—to help you
          reach your goals.
        </p>
        <p>
          We started Measured because we&apos;ve struggled with weight
          ourselves, and have countless family members who have felt the effects
          of obesity. Whether it&apos;s diabetes, high cholesterol or
          hypertension, getting to a healthier weight can have a profound impact
          on your life.
        </p>
        <p>
          But it&apos;s not always obvious what you&apos;re supposed to do, and
          it can be tough to go at it alone. That&apos;s why we&apos;ve built a
          program that is tailored to meet each person&apos;s individual needs,
          supported by some of the best health coaches and physicians in the
          world. We use science-backed methods but turn them into simple,
          easy-to-follow instructions because life is already hard enough. We
          know it&apos;s hard - that&apos;s why we&apos;re here. We&apos;re in
          this together, and we&apos;re going to make sure you get to that
          finish line.
        </p>
        <div className="mt-10 flex gap-2.5 items-center">
          <Image
            src="/monji-sig.png"
            alt="Monji' Signatures"
            width={110}
            height={36}
          />
          <Image src="/sign-plus.svg" alt="Signatures" width={13} height={10} />
          <Image
            src="/frankie-sig.png"
            alt="Frankie' Signatures"
            width={77}
            height={48}
          />
        </div>
        <p>Monji & Frankie, Measured Co-Founders</p>
      </div>
    </div>
  )
}
