import React, { useState } from 'react'
import Image from 'next/image'

interface PlanDetailsCardProps {
  title: string
  image?: string
  description?: string
  features?: string[]
  MoreInfo?: any
  step?: number
}

export default function PlanDetailsCard({
  title,
  description,
  features,
  image,
  MoreInfo,
  step,
}: PlanDetailsCardProps) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="card-item">
        {!!step && <div className="card-step">{step}</div>}
        <div className="card-data">
          {image && (
            <div className="card-img-container">
              <Image
                src={image}
                width={64}
                height={64}
                alt={title}
                className="object-cover"
              />
            </div>
          )}
          <div className="flex-1">
            <h3 className="card-heading">
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </h3>

            {description && <p className="card-body">{description}</p>}
            {Array.isArray(features) && features?.length > 0 && (
              <ul className="card-body list-disc list-outside ml-4">
                {features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            )}
          </div>

          {MoreInfo && (
            <div className="absolute bottom-[2px] right-[10px]">
              <button type="button" onClick={() => setOpen(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-7 h-7 text-secondary1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
      {MoreInfo && <MoreInfo open={open} setOpen={setOpen} />}
    </>
  )
}
