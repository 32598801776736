import React, { useMemo } from 'react'
import { StepComponentProps, SliderQuizStep } from '@types'
import Actions from '@components/quiz/actions'
import InputRange, { Range } from 'react-input-range'
import * as selectors from 'redux/user'
import { useAppSelector } from 'redux/hooks'
type Props = StepComponentProps<SliderQuizStep, number | Range>

export default function Slider(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props
  const nextEnabled = useMemo(() => !!value, [value])

  const maxValue = useAppSelector(state =>
    (selectors as any)[step.maxSelector](state, value as number),
  )

  const handleInputChange = (value: number | Range) => {
    handleNext(value)
  }

  const handleNext = (newValue: number | Range) => {
    onChange(step.id, newValue)
  }

  return (
    <>
      <div className="quiz-slider-input-range">
        <InputRange
          maxValue={maxValue}
          minValue={step.min}
          step={5}
          value={value}
          formatLabel={value => {
            return `${value}${value >= 300 ? '+' : ''} lbs`
          }}
          onChange={handleInputChange}
        />
      </div>
      <Actions
        onNext={() => handleNext(value)}
        nextEnabled={nextEnabled}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </>
  )
}
