import { useExperiment } from 'redux/experiment'

export default function TreatmentPlan() {
  const { experiment } = useExperiment()

  return (
    <div>
      {experiment?.dictionary?.planPage?.title && (
        <h1 className="quiz-heading">
          {experiment?.dictionary?.planPage?.title}
        </h1>
      )}
      {experiment?.dictionary?.planPage?.paragraph && (
        <div className="quiz-paragraph">
          {experiment?.dictionary?.planPage?.paragraph}
        </div>
      )}
      <div className="your-plan-container">
        <div className="cards">
          {experiment?.planPageBoxes?.map(Box => Box)}
        </div>
      </div>
    </div>
  )
}
