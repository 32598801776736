import React from 'react'
import { Plan } from '@types'
import CheckMark from '@components/CheckMark'

type PlanSelectorProps = {
  selectedPlan: Plan
  plans: Array<Plan>
  onSelectPlan: (plan: Plan) => void
}
const PlanSelector = ({
  selectedPlan,
  plans = [],
  onSelectPlan,
}: PlanSelectorProps) => {
  return (
    <div className="plan-selector-container">
      {plans.map(plan => {
        const selected = plan.id === selectedPlan.id
        const { salePrice, duration } = plan

        return (
          <div
            key={plan.id}
            onClick={() => onSelectPlan(plan)}
            className={`plan-item ${selected && 'selected'}`}
          >
            <div className="flex items-center gap-2">
              <CheckMark selected={selected} />
              <span>{duration} Month Plan</span>
            </div>
            <span>${Math.floor(salePrice / duration)} per month</span>
          </div>
        )
      })}
    </div>
  )
}

export default PlanSelector
