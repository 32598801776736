import Quiz from '@components/quiz'
import { NumberParam, useQueryParam } from 'use-query-params'
import { useExperiment } from 'redux/experiment'

const QuizPage = () => {
  const { experiment } = useExperiment()
  const [step] = useQueryParam('step', NumberParam)
  const quiz = experiment?.questions

  return !quiz ? null : (
    <Quiz
      step={quiz[(step || 1) - 1]}
      currentStep={step || 1}
      nextStep={(step || 1) < quiz.length ? (step || 1) + 1 : null}
      previousStep={(step || 1) - 1}
      isLastStep={step === quiz.length}
      stepsCount={quiz.length}
    />
  )
}
export default QuizPage
