import { rejects } from 'assert'
import { create } from 'domain'
import {
  PurchaseOrder,
  PurchaseResponse,
  StripeIntentCreateParams,
  StripeIntentResponse,
  StripeIntentUpdateParams,
} from '../@types'
import { StripeCheckout } from '../components/Checkout/Stripe'

const stripePaymentAction = async (params: any) => {
  return await fetch('/api/stripeCheckout', {
    body: JSON.stringify({
      ...params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
}

const createStripePaymentIntent = (
  params: StripeIntentCreateParams,
): Promise<StripeIntentResponse> => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch('/api/stripeIntent/create', {
      body: JSON.stringify({
        ...params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    if (response.ok) {
      const data: StripeIntentResponse = await response.json()
      resolve(data)
    } else {
      reject()
    }
  })
}

const updateStripePaymentIntent = (
  params: StripeIntentUpdateParams,
): Promise<StripeIntentResponse> => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch('/api/stripeIntent/update', {
      body: JSON.stringify({
        ...params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    if (response.ok) {
      const data: StripeIntentResponse = await response.json()
      resolve(data)
    } else {
      reject()
    }
  })
}

const purchaseOrder = (payload: PurchaseOrder): Promise<PurchaseResponse> =>
  new Promise((resolve, reject) => {
    fetch('/api/purchase', {
      body: JSON.stringify({
        env: process.env.VERCEL_ENV,
        host: process.env.PUBLIC_TEMP_APP_ROOT_URL,
        ...payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(res => resolve(res.json()))
  })

const stripeIntentApi = {
  create: createStripePaymentIntent,
  update: updateStripePaymentIntent,
}

export { stripeIntentApi, purchaseOrder, StripeCheckout, stripePaymentAction }
