import {
  PaymentProcessorData,
  Purchased,
  PurchaseResponse,
  Track,
} from '@types'
import {
  selectEmail,
  selectLeadId,
  selectMagicLink,
  selectName,
  selectPhone,
  setMagicLink,
  setUserId,
} from 'redux/user'
import {
  selectCoupon,
  selectPaymentProcessorData,
  selectProcessor,
  selectPurchaseComplete,
  selectPurchased,
  setPurchaseComplete,
} from 'redux/order'
import fireConfetti from '@utils/confetti'
import { useEffect, useState } from 'react'
import { purchaseOrder } from '@utils/payments'
import { useAppSelector } from 'redux/hooks'
import { useDispatch } from 'react-redux'
import { selectLocation } from 'redux/quiz'
import ScreenLoading from '@components/ScreenLoading'
import { applyCouponToPlan } from '@utils/plans'
import ContinueButton from '@components/ContinueButton'
import Router from 'next/router'
import { initialDrConsutation } from '@data/plans/pt1'
import Modal from '@components/Modal'
import { getEventId } from '@utils/tracking'

const LineItem = ({
  name,
  price,
  extraClasses = undefined,
}: {
  name: string
  price: React.ReactElement | string
  extraClasses?: string
}) => {
  return (
    <div className={['flex justify-between', extraClasses].join(' ')}>
      <div className="text-base">{name}</div>
      <div
        className="line flex-1 h-4 mx-2"
        style={{ borderStyle: 'dashed' }}
      ></div>
      <div className="price-value">{price}</div>
    </div>
  )
}

const RecipeModal = ({
  paymentProcessorData,
}: {
  paymentProcessorData: PaymentProcessorData
}) => {
  const purchased = useAppSelector(selectPurchased)
  const coupon = useAppSelector(selectCoupon)
  const totalPrice = paymentProcessorData?.totalPaid / 100
  const total = (
    <LineItem
      name="Total Paid Today"
      price={`$${totalPrice}`}
      extraClasses="mt-4 pt-4 font-semibold border-t"
    />
  )

  const purchasedList = purchased.map((item: Purchased, idx: number) => {
    let couponLine

    if (coupon) {
      const updatedPlan = applyCouponToPlan(
        paymentProcessorData.selectedPlan,
        coupon,
      )
      couponLine = (
        <LineItem
          name={`Coupon - ${updatedPlan.valueText}`}
          price={`-$${updatedPlan.valueAmount}`}
          extraClasses="discount-line text-red-800"
        />
      )
    }

    let name = `${
      item.track === Track.BEHAVIORAL ? 'Measured Nutrition Plan™ - ' : ''
    } ${item.productNameLong || item.productName}`
    if (paymentProcessorData.trial) {
      name = initialDrConsutation.line_item
    }
    return (
      <>
        <LineItem
          key={idx}
          name={name}
          price={totalPrice === 0 ? <em>FREE</em> : `$${totalPrice}`}
        />
        {couponLine}
      </>
    )
  })

  return (
    <div className="mx-auto">
      <div className="text-lg purchased-list">
        {purchasedList}
        {total}
      </div>
    </div>
  )
}

const ErrorMessage = () => (
  <div className="page-cont flex flex-col items-center py-5 justify-start">
    <h1 className="quiz-heading-3xl mb-4 error">
      Problem with processing your order
    </h1>
    <div className="max-w-lg mx-auto my-4">
      <div className="text-center">
        <p className="quiz-paragraph mb-5">
          Please refresh the page. If the problem persists, please email{' '}
          <a
            className="border-b border-brand-primary-4-darker font-semibold cursor-pointer text-brand-primary-4-darker hover:text-brand-primary-4-lighter"
            href="mailto:support@trymeasured.com"
          >
            support@trymeasured.com
          </a>{' '}
          to complete your order.
        </p>

        <h6 className="quiz-heading-lg font-medium">- Measured Team</h6>
      </div>
    </div>
  </div>
)

export default function Confirmation() {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(true)
  const purchased = useAppSelector(selectPurchased)
  const leadId = useAppSelector(selectLeadId) as string
  const location = useAppSelector(selectLocation)
  const processor = useAppSelector(selectProcessor)
  const paymentProcessorData = useAppSelector(selectPaymentProcessorData)
  const email = useAppSelector(selectEmail) as string
  const name = useAppSelector(selectName) as string
  const phone = useAppSelector(selectPhone) as string
  const purchaseComplete = useAppSelector(selectPurchaseComplete)
  const [error, setError] = useState(false)
  const magicLink = useAppSelector(selectMagicLink)
  const [showModal, setShowModal] = useState(false)

  const toggleReceiptModal = () => {
    setShowModal(!showModal)
  }

  const totalPrice = paymentProcessorData?.totalPaid / 100

  useEffect(() => {
    const getTransactionId = (): string => {
      return `${processor}-${paymentProcessorData.paymentId}`
    }
    const fireGAEvent = () => {
      const dataLayer = window.dataLayer || []
      const products = purchased.map((p: any) => {
        const { name, id, price, category, variant, quantity } = p
        return {
          name,
          id,
          price,
          category,
          variant,
          quantity,
        }
      })
      const dataObj = {
        event: 'transaction',
        ecommerce: {
          purchase: {
            actionField: {
              id: getTransactionId(),
              affiliation: 'MKP',
              revenue: totalPrice,
              tax: 0,
              shipping: 0,
            },
            products,
          },
        },
      }
      dataLayer.push(dataObj)
    }

    const finishPayment = (response: PurchaseResponse) => {
      setProcessing(false)
      dispatch(setUserId(response.data.id))
      dispatch(setMagicLink(response.data.attributes.magicLink))
      dispatch(setPurchaseComplete(true))
      fireGAEvent()
      fireConfetti({ count: 200 })
    }

    if (processor && !purchaseComplete) {
      purchaseOrder({
        email,
        phone,
        name,
        processor,
        selectedPlan: paymentProcessorData.selectedPlan,
        paymentId: paymentProcessorData.paymentId,
        totalPaid: paymentProcessorData.totalPaid,
        referrer: paymentProcessorData.referrer,
        subscriptionId: paymentProcessorData.subscriptionId,
        customerId: paymentProcessorData.customerId,
        purchaseEventId: getEventId('Purchase'),
        leadId,
        trial: paymentProcessorData.trial,
        discounts: [],
        paymentProcessorData,
        location,
      })
        .then(response => finishPayment(response))
        .catch(() => setError(true))
    } else {
      setProcessing(false)
      fireConfetti({ count: 200 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    email,
    leadId,
    location,
    name,
    paymentProcessorData,
    phone,
    processor,
    purchased,
    totalPrice,
  ])

  if (processing) {
    return <ScreenLoading text="Processing..." />
  }

  if (error) {
    return <ErrorMessage />
  }

  const letsGetStarted = () => {
    magicLink && Router.push(magicLink)
  }

  return (
    <>
      <Modal
        showModal={showModal}
        toggleModal={toggleReceiptModal}
        modalClass="modal-phone-hint"
        modalTitle="Your Receipt"
      >
        <div className="text-center p-4">
          <div className="rounded-xl text-left">
            <RecipeModal paymentProcessorData={paymentProcessorData} />
          </div>
          <button
            onClick={toggleReceiptModal}
            className="mt-4 bg-brand-primary-4-default hover:bg-brand-primary-4-lighter text-black rounded px-x py-2 block w-full font-semibold"
          >
            Close
          </button>
        </div>
      </Modal>
      <div className="p-2 md:p-4 max-w-md md:max-w-lg mx-auto">
        <h2 className="quiz-heading center">You&apos;re in! 🎉</h2>
        <p className="quiz-heading-lg">
          We&apos;re so excited to work with you.
        </p>
        <p className="quiz-heading-lg">
          There are a couple of more steps to go before your clinician can see
          you.{' '}
          <span className="font-medium italic">
            Click the button below to continue.
          </span>
        </p>

        <div className="max-w-xl mx-auto mt-4 sm:mt-4 col-span-12 lg:col-span-5 md:px-4 mb-10">
          <div className="text-center text-sm max-w-sm mx-auto">
            <p className="text-sm font-medium leading-6 text-secondary1 text-center opacity-60">
              We&apos;ve emailed a copy of your receipt to{' '}
              <b>{paymentProcessorData?.email}</b>. If you don&apos;t see it, be
              sure to check your Spam or Promotions folder.
              <br />
              You can also{' '}
              <span className="underline" onClick={toggleReceiptModal}>
                view it here
              </span>
              .
            </p>
          </div>
        </div>

        {magicLink && (
          <div className="max-w-xs mx-auto mt-20">
            <ContinueButton
              enabled={true}
              text="Let's Get Started!"
              onClick={letsGetStarted}
            />
          </div>
        )}
      </div>
    </>
  )
}
