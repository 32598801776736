import React, { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { resetEligiblity, selectIsEligible } from 'redux/eligiblity'

type EligibilityGuardProps = {
  children: React.ReactNode
}
const EligibilityGuard: React.FunctionComponent<EligibilityGuardProps> = ({
  children,
}) => {
  const router = useRouter()
  const isEligible = useAppSelector(selectIsEligible)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetEligiblity())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const eligiblityCheck = useCallback(
    (url: string) => {
      const path = url.split('?')[0]
      if (!isEligible && !path.includes('/ineligible')) {
        router.push({
          pathname: '/ineligible',
        })
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEligible],
  )

  useEffect(() => {
    eligiblityCheck(router.asPath)

    router.events.on('routeChangeComplete', eligiblityCheck)

    return () => {
      router.events.off('routeChangeComplete', eligiblityCheck)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEligible])

  return <>{children}</>
}

export default EligibilityGuard
