const dictionary = {
  results: {
    title: 'Your Personalized Medical Weight Loss Plan Is Ready',
    paragraph:
      'Based on your answers, you can lose up to 6 lbs in your first month on your plan.',
  },
  planPage: {
    title: 'Your Treatment Plan',
  },
  whatsNextPage: {
    title: 'What Happens Next',
    steps: [
      {
        title: 'Initial Consultation ($39)',
        description:
          'Conduct a virtual visit with an obesity-trained clinician. Next day appointments available.',
      },
      {
        title: 'Review Your Plan',
        description:
          'Before moving forward, review your plan and discuss options with your clinician.',
      },
      {
        title: 'Treatment Plan ($78/mo)',
        description:
          'Prescription meds, 24/7 doctor access, health coaching, and access to private community.',
      },
    ],
  },
}

export default dictionary
