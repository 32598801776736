import { Experiment } from '@types'
import pt1 from '../pt1'

const survey: Experiment = {
  ...pt1,
  id: 'pt1',
  funnel: 'pt-initial-consult-home',
  default: true,
}

export default survey
