import {
  QuizInsurance,
  QuizSteps,
  QuizStepType,
  StepId as StepIdBase,
  StepId,
} from '@types'
import { conditionsQuestion } from '@data/questions/base'

export const questions: QuizSteps[] = [
  {
    id: StepId.GENDER,
    type: QuizStepType.SINGLE_CHOICE,
    label:
      "Get your <span class='mc-callout-1'>personalized weight loss plan</span> by<br class='hidden md:block' /> completing this short quiz.",
    options: [
      {
        name: 'Male',
        value: 'male',
      },
      {
        name: 'Female',
        value: 'female',
      },
    ],
  },
  {
    id: StepId.HEIGHT_WEIGHT,
    type: QuizStepType.HEIGHT_WEIGHT,
    label: 'What is your height and weight?',
    hint: "We'll use this to calculate your Body Mass Index (BMI).",
    showHeight: true,
    showWeight: true,
    weightId: StepId.CURRENT_WEIGHT,
    whyDoWeAsk: {
      description:
        'We use this to calculate your Body Mass Index (BMI), which is a factor that helps determine your weight loss plan.',
    },
  },
  {
    id: StepId.CONTENT_PERSONALIZED,
    type: QuizStepType.CONTENT,
    content_type: 'html',
    label: 'How we create your plan',
    hint: "<span class='callout-text'>We ask a few questions about your background, health history, and biology that will help us determine if you're eligible for a Measured medical plan.<br /><br /> Once you complete your lab work, your clinician will reviews the results and health history to determine the appropriate treatment plan.</span>",
  },
  {
    id: StepId.WEIGHT_LOSS,
    type: QuizStepType.SLIDER,
    label:
      'How much weight would<br class="hidden md:block" /> you like to lose?',
    min: 0,
    maxSelector: 'selectMaxWeightLoss',
  },
  {
    id: StepId.BIRTHDAY,
    type: QuizStepType.BIRTHDAY,
    label: "What's your date of birth?",
    _comment:
      'Should have a content slide after this that either explains how we use this data or sets up the following questions.',
  },
  {
    id: StepId.INSURANCE_PROVIDER as StepIdBase,
    type: QuizStepType.INSURANCE,
    label:
      'What form of health insurance will you use to fulfill your prescription?',
    options: [
      {
        name: 'Kaiser',
        value: QuizInsurance.KAISER,
      },
      {
        name: 'Medicare',
        value: QuizInsurance.MEDICARE,
      },
      {
        name: 'Medicaid',
        value: QuizInsurance.MEDICAID,
      },
      {
        name: 'Tri-care',
        value: QuizInsurance.TRI_CARE,
      },
      {
        name: 'Employer/Commercial (Anthem, Blue Cross, etc.)',
        value: QuizInsurance.COMMERCIAL,
      },
      {
        name: "I don't have insurance",
        value: QuizInsurance.NONE,
      },
    ],
  },
  {
    id: StepId.ZIPCODE,
    type: QuizStepType.ZIPCODE,
    label: 'What is your ZIP code?',
  },
  { ...conditionsQuestion },
  {
    id: StepId.RACE,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'What is your race?',
    options: [
      {
        name: 'Multi-racial',
        value: 'multiracial',
      },
      {
        name: 'Hispanic or Latino',
        value: 'hispanic',
      },
      {
        name: 'Black or African',
        value: 'black',
      },
      {
        name: 'White or Caucasian',
        value: 'white',
      },
      {
        name: 'Asian',
        value: 'asian',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
    ],
    _comment:
      'Good opportunity in next slide to tell them about how race can impact weight loss.',
  },
  {
    id: StepId.HEALTH_CONDITIONS,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: '(you can pick more than one)',
    compact: true,
    label: 'Do you have any of the below health conditions?',
    options: [
      {
        name: 'Diabetes',
        value: 'diabetes',
      },
      {
        name: 'Heart Disease',
        value: 'heart_disease',
      },
      {
        name: 'High Blood Pressure',
        value: 'high_blood_pressure',
      },
      {
        name: 'High Cholesterol',
        value: 'high_cholesterol',
      },
      {
        name: 'Sleep Apnea',
        value: 'sleep_apnea',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.LAST_IDEAL,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How long has it been since you were at your ideal weight?',
    options: [
      {
        name: 'Less than 1 year',
        value: 'less_than_one_year',
      },
      {
        name: '1-2 years',
        value: 'one_to_two_years',
      },
      {
        name: 'More than 3 years',
        value: 'more_than_three_years',
      },
      {
        name: 'Never',
        value: 'never',
      },
    ],
  },
  {
    id: StepId.CONTENT_PERSONALIZED,
    type: QuizStepType.CONTENT,
    content_type: 'html',
    label: 'Set Goals &amp; Develop Healthy Habits',
    hint: 'Hear about Ann&#39;s story',
    content:
      '<div class="review-content"><div class="quote">“For the first time in years I am losing weight and feel great. I\'ve been able to control my eating habits better than before. I love watching the number go down!!”</div><div class="user-info"><img src="/testimonial.jpeg" alt="Ann Pettit"><span>Ann Pettit</span><img src="/testimonial-five-stars.svg" alt="Testimonial five stars"></div></div>',
  },
  {
    id: StepId.PREFERRED_RATE,
    type: QuizStepType.SINGLE_CHOICE,
    label: "How fast do you want<br class='sm:hidden' /> to lose it?",
    options: [
      {
        name: '0.5 lbs a week',
        value: '0.5',
      },
      {
        name: '1 lb a week',
        value: '1',
      },
      {
        name: '1.5 lbs a week',
        value: '1.5',
      },
      {
        name: '2 lbs a week',
        value: '2',
      },
    ],
    _comment:
      "Would be nice to ask if they're losing weight for a specific goal after this question.",
  },
  {
    id: StepId.ACTIVITY_LEVEL,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How physically active are you?',
    hint: 'Select an option from below.',
    options: [
      {
        name: 'Not very active',
        value: 'low',
      },
      {
        name: 'Exercise 1-2 times per week',
        value: 'medium',
      },
      {
        name: 'Exercise 3-5 times per week',
        value: 'high',
      },
      {
        name: 'Exercise 6+ times per week',
        value: 'very_high',
      },
    ],
  },
  {
    id: StepId.TYPICAL_DAY,
    type: QuizStepType.SINGLE_CHOICE,
    label: "What's your typical day like?",
    hint: 'Getting fit later in life requires a more personalized approach depending on your current lifestyle.',
    options: [
      {
        name: 'At the office',
        value: 'works_at_office',
      },
      {
        name: 'Daily long walks',
        value: 'takes_long_walks',
      },
      {
        name: 'Physical work',
        value: 'does_physical_work',
      },
      {
        name: 'Mostly at home',
        value: 'stay_at_home',
      },
    ],
  },
  {
    id: StepId.HABITS,
    type: QuizStepType.MULTIPLE_CHOICE,
    label: 'What are your habits?',
    hint: "(pick as many as you'd like)",
    options: [
      {
        name: 'I eat late at night',
        value: 'eat_late_at_night',
      },
      {
        name: "I don't get enough sleep",
        value: 'not_enough_sleep',
      },
      {
        name: "I can't give up sweets",
        value: 'too_many_sweets',
      },
      {
        name: 'I love soft drinks',
        value: 'soft_drinks',
      },
      {
        name: 'I eat too much salt',
        value: 'too_much_salt',
      },
      {
        name: 'None of above',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.STRUGGLE,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: "(pick as many as you'd like)",
    compact: true,
    label: 'What are your biggest struggles with losing weight?',
    options: [
      {
        name: 'Lack of motivation',
        value: 'lack_motivation',
      },
      {
        name: 'Tempted by cravings',
        value: 'cravings',
      },
      {
        name: 'Stress eating',
        value: 'stress_eating',
      },
      {
        name: 'No time to cook',
        value: 'no_cookinng',
      },
      {
        name: 'Living with family',
        value: 'family',
      },
      {
        name: 'Health issues',
        value: 'health',
      },
    ],
  },
  {
    id: StepId.OTHER_DIETS,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: "(pick as many as you'd like)",
    compact: true,
    label: 'Have you tried any of the following diets before?',
    options: [
      {
        name: 'Weight Watchers',
        value: 'weight_watchers',
      },
      {
        name: 'Noom',
        value: 'noom',
      },
      {
        name: 'Keto',
        value: 'keto',
      },
      {
        name: 'Intermittent Fasting',
        value: 'intermittent_fasting',
      },
      {
        name: 'Low Carb',
        value: 'low_carb',
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.CONTENT_SET_POINT,
    type: QuizStepType.CONTENT,
    content_type: 'html',
    image: '/measured-guarantee.svg',
    label: 'The Measured Guarantee',
    hint: "We stand by our product -- if you're not completely satisfied with your membership just let us know and we'll work with you to make things right.",
  },
  {
    id: StepId.HEAR_ABOUT,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How did you hear about us?',
    options: [
      {
        name: 'Instagram',
        value: 'instagram',
      },
      {
        name: 'Facebook',
        value: 'facebook',
      },
      {
        name: 'Web search',
        value: 'web_search',
      },
      {
        name: 'Friend / Family',
        value: 'friend_family',
      },
      {
        name: 'News article',
        value: 'news_article',
      },
      {
        name: 'Email',
        value: 'email',
      },
      {
        name: 'Tik Tok',
        value: 'tik_tok',
      },
    ],
  },
]
