import { Plan } from '@types'

const defaultPlans: Record<number, number> = {
  1: 3,
  2: 4,
  3: 9,
}

const defaultPlanId = (groupId: number): number => {
  return defaultPlans[groupId]
}

export const applyCouponToPlan = (plan: Plan, coupon: any) => {
  let valueText
  let valueAmount

  if (!coupon) {
    return plan
  }

  if (coupon.percent_off) {
    valueText = `${coupon.percent_off}% off`
    valueAmount = plan.salePrice * (coupon.percent_off / 100)
  } else if (coupon.amount_off) {
    valueText = `$${coupon.amount_off / 100} off`
    valueAmount = coupon.amount_off / 100
  }

  return {
    ...plan,
    valueText,
    valueAmount,
    couponApplied: true,
    salePrice: valueAmount ? plan.salePrice - valueAmount : plan.salePrice,
  }
}

export { defaultPlanId, defaultPlans }
