import { HelpCircle } from 'react-feather'
import React, { useState } from 'react'
import { OrderPlanItemHintDialog } from './OrderPlanItemHintDialog'

export const OrderPlanItemHint = ({ description }: { description: string }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button
        className="text-secondary1 opacity-60"
        onClick={() => setOpen(true)}
        type="button"
      >
        <HelpCircle className="h-5 w-5 relative back-nav cursor-pointer" />
      </button>
      <OrderPlanItemHintDialog
        open={open}
        description={description}
        setOpen={setOpen}
      />
    </>
  )
}
