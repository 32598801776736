import { useAppDispatch } from '../redux/hooks'
import { ReactNode, useEffect } from 'react'
import { clearCoupon, setCoupon } from '../redux/order'
import { ParsedUrlQuery } from 'querystring'

interface ICouponProviderProps {
  query: ParsedUrlQuery
  children: ReactNode
}

const QUERY_PARAM = 'coupon'

const CouponProvider = ({ query, children }: ICouponProviderProps) => {
  const dispatch = useAppDispatch()
  let coupon: string | undefined | null = null

  useEffect(() => {
    if (query[QUERY_PARAM]) {
      const fetchCoupon = async () => {
        coupon = query[QUERY_PARAM]?.toString()
        if (coupon) {
          const couponResponse = await fetch('/api/coupon', {
            body: JSON.stringify({
              coupon,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }).then(response => response.json())

          if (couponResponse.success) {
            dispatch(
              setCoupon({
                code: coupon,
                ...couponResponse.coupon,
              }),
            )
          }
        }
      }

      dispatch(clearCoupon())
      fetchCoupon()
    }
  }, [query])

  return <div>{children}</div>
}

export default CouponProvider
