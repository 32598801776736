import { ExperimentPlan, Plan } from '@types'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectSelectedPlan, setSelectedPlan } from '../../redux/order'
import { selectEmail } from '../../redux/user'
import { trackEvent } from '@utils/tracking'
import PlanSelector from '@components/Plan/PlanSelector'
import ContinueButton from '@components/ContinueButton'
import { useEffect } from 'react'
import { useExperiment } from 'redux/experiment'
import { trackKlaviyoEvent } from '@components/Scripts/Klaviyo'

export default function SelectPlan({ onClick }: { onClick: () => void }) {
  const selectedPlan = useAppSelector(state => selectSelectedPlan(state))
  const { experiment } = useExperiment()
  const { defaultPlan, medicalPlans, initialDrConsutation } =
    experiment?.plans as ExperimentPlan
  const dispatch = useAppDispatch()
  const email = useAppSelector(selectEmail)
  const showPlanSelector = experiment?.dictionary?.planPage?.showPlanSelector
  const oneTimePurchase = experiment?.oneTimePurchase

  // Reset the selected plan on page load / refresh.
  useEffect(() => {
    dispatch(setSelectedPlan(defaultPlan()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalPlans])
  const planSelectHandler = (plan: Plan) => {
    trackEvent({
      eventName: 'AddToCart',
      advancedMatchingOptions: { em: email as string },
    })
    trackKlaviyoEvent('AddToCart', experiment?.funnel, email, {})
    dispatch(setSelectedPlan(plan))
  }

  return (
    <div>
      {showPlanSelector && (
        <h1 className="quiz-heading-lg">Select Your Plan</h1>
      )}
      {showPlanSelector && (
        <PlanSelector
          selectedPlan={selectedPlan}
          plans={medicalPlans}
          onSelectPlan={planSelectHandler}
        />
      )}
      <ContinueButton
        text={
          !showPlanSelector
            ? oneTimePurchase
              ? `Get Started for $${initialDrConsutation?.salePrice}`
              : 'Continue'
            : `Get ${selectedPlan.duration} Month${
                selectedPlan.duration > 1 ? 's' : ''
              } for ${
                selectedPlan.salePrice != selectedPlan.originalPrice
                  ? `<s>$${selectedPlan.originalPrice}</s>`
                  : ''
              } $${selectedPlan.salePrice}`
        }
        enabled
        onClick={onClick}
      />
    </div>
  )
}
