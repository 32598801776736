import { trackEvent } from '@utils/tracking'
import { useEffect } from 'react'
import ResultsPage from '../../components/Results'
import { useAppSelector } from '../../redux/hooks'
import { selectAnswers } from '../../redux/quiz'
import {
  selectCurrentWeight,
  selectEmail,
  selectGender,
  selectMacros,
  selectTargetWeight,
  selectProgressAmount,
  selectTrack,
} from '../../redux/user'

const defaultMacros = {
  bmi: 25,
  minCalories: 1650,
  maxCalories: 1850,
  avgCalories: 1750,
  water: 2.8,
}

export default function Results() {
  const email = useAppSelector(selectEmail)
  const macros = useAppSelector(selectMacros)
  const progressAmount = useAppSelector(selectProgressAmount)
  const currentWeight = useAppSelector(state =>
    selectCurrentWeight(state, 'imperial'),
  )
  const targetWeight = useAppSelector(state =>
    selectTargetWeight(state, 'imperial'),
  )
  const height = useAppSelector(selectAnswers).height
  const gender = useAppSelector(selectGender)
  const track = useAppSelector(selectTrack)

  useEffect(() => {
    trackEvent({
      eventName: 'ViewResults',
      advancedMatchingOptions: { em: email as string },
    })
  }, [email])

  const pageProps = {
    currentWeight: currentWeight || 150,
    targetWeight: targetWeight || 140,
    progressAmount,
    units: 'imperial',
    amount: progressAmount || 10,
    gender: gender || 'male',
    macros: macros || defaultMacros,
    track,
    height,
    email,
  }

  return <ResultsPage {...pageProps} />
}
