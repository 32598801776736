/* eslint-disable */
export const trackKlaviyoEvent = (
  event_name: string,
  funnel?: string,
  email?: string | null,
  props?: Record<any, any>,
) => {
  try {
    if ((window as any)._learnq) {
      // Identify the current user by their email address
      if (email) {
        ;(window as any)._learnq.push([
          'identify',
          {
            $email: email,
            $source: `funnel-${funnel || '8am-pt1'}`,
          },
        ])
      }

      // track an event
      ;(window as any)._learnq.push([
        'track',
        event_name,
        {
          ...props,
        },
      ])
    }
  } catch (e) {
    console.log(e)
  }
}
